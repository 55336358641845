/*
编辑图形
*/
import {
    MouseHandle
} from "./mouseHandle.js"

import {
    Color as CesiumColor,
    Cartesian3 as CesiumCartesian3,
    defined as Cesiumdefined
} from "cesium/Cesium";

import {
    createControlPoint,
    createMarker,
    createPolyLine,
    createPolygon
} from './entities'
var uuid = require('uuid');

//现在正在编辑的类型
let typeEdit = {
    noneEdit: -1,
    makerEdit: 0,
    polylineEdit: 1,
    polygonEdit: 2
}

class EditGraphic extends MouseHandle {
    constructor(viewer, options = null) {
        //super关键字等价于父类构造方法
        super(viewer, options);
        this.editNode = null
        this.isStart = false;
        this.isMove = false;
        this.isStartMove = false;
        this.typeEdit = typeEdit.noneEdit
        this.controlPointEntity = null //当前选中的控制点
        this.bufferInfo = null
    }

    onClickEdit(e) {
        if (!this.options.editMode) return
        const obj = this.viewer.scene.pick(e.position)
        if (Cesiumdefined(obj) && obj.id) {
            this.editNode = obj.id
            if (this.editNode.hasOwnProperty("_workMode")) return
            if (this.editNode.hasOwnProperty('bufferInfo')) {
                this.isStart = true
                this.startEdit()
            }
        }
    }

    onLeftClick(e) {
        if (this.isStart) {
            this.endEdit(e);
        } else {
            this.onClickEdit(e);
        }
    }

    onLeftClickDown(e) {
        if (this.isStartMove) {
            this.isMove = true;
            this.viewer.scene.screenSpaceCameraController.enableRotate = false
        }
    }
    onLeftClickUp(e) {
        this.isMove = false;
        this.isStartMove = false;
        this.viewer.scene.screenSpaceCameraController.enableRotate = true
    }
    onMove(e) {
        this.getCurrentObj(e); //当前滑动上的标绘

        if (!this.options.editMode) return
        this.setMouseStyle(e)
        if (!this.isStart) return
        this.setControlPointColor(e)
        this.mouseMove(e)
    }

    getCurrentObj(e) {
        const obj = this.viewer.scene.pick(e.endPosition)
        let entity = null
        if (Cesiumdefined(obj)) {
            entity = obj.id
        }
        if (this.isStart) {
            if (entity) entity.uuidList = this.uuidList
        }
        let position = this.getMousePoint(e, 'move')
        this.options.getCurrentObj({
            entity,
            position
        })
    }

    setMouseStyle(e) {
        let cesiumContainer = document.getElementById("cesiumContainer");
        cesiumContainer.style.cursor = "default";
        const obj = this.viewer.scene.pick(e.endPosition)
        if (Cesiumdefined(obj)) {
            cesiumContainer.style.cursor = "pointer";
        }
    }
    onRightClick(e) {
        this.endEdit(e);
    }
    mouseMove(e) {
        if (this.typeEdit == typeEdit.makerEdit) {
            this.mouseMoveMarker(e)
        } else {
            if (this.controlPointPrimitiveList.length > 0 && this.isMove) {
                let index = this.getControlPointIndex();
                if (index < 0) return
                let position = this.getMousePoint(e, 'move')
                this.positions[index].lng = position.lng;
                this.positions[index].lat = position.lat;
                this.positions[index].alt = position.alt;
                let cartesianPosition = CesiumCartesian3.fromDegrees(position.lng, position.lat)
                this.controlPointList[index].x = cartesianPosition.x;
                this.controlPointList[index].y = cartesianPosition.y;
                this.controlPointList[index].z = cartesianPosition.z;
                this.getGeneratePoints(this.bufferInfo.options);
            }
        }
    }

    //得到控制点的序号
    getControlPointIndex() {
        return this.uuidList.indexOf(this.controlPointEntity.id)
    }
    mouseMoveMarker(e) {
        if (this.controlPointPrimitive && this.isMove) {
            let position = this.getMousePoint(e, 'move')
            this.position.lng = position.lng;
            this.position.lat = position.lat;
            this.position.alt = position.alt;
            let cartesianPosition = CesiumCartesian3.fromDegrees(position.lng, position.lat)
            this.cartesianPosition.x = cartesianPosition.x;
            this.cartesianPosition.y = cartesianPosition.y;
            this.cartesianPosition.z = cartesianPosition.z;
        }
    }

    //开始编辑
    startEdit() {
        this.options.isEditingCallback(true);
        let bufferInfo = this.initEditStyle(this.editNode);
        this.viewer.entities.remove(this.editNode)
        this.bufferInfo = bufferInfo
        this.bufferInfo.id = uuid.v1() //添加唯一标识

        //开始编辑点标记
        if (bufferInfo.type == 'marker') {
            this.startMarker(bufferInfo)
        } else if (bufferInfo.type == 'polyline' || bufferInfo.type == 'polygon') {
            this.positions = bufferInfo.positions
            this.getGeneratePoints(bufferInfo.options);
            if (bufferInfo.type == 'polyline') {
                this.startPolyline(bufferInfo)
            } else {
                this.startPolygon(bufferInfo)
            }

            this.controlPointPrimitiveList = []
            this.controlPointList = []
            this.uuidList = []
            for (let item of this.positions) {
                let cartesianPosition = CesiumCartesian3.fromDegrees(item.lng, item.lat)
                this.controlPointList.push(cartesianPosition)
                let id = uuid.v1()
                this.uuidList.push(id)
                let ControlPointPrimitive = createControlPoint(this, id)
                this.controlPointPrimitiveList.push(new ControlPointPrimitive(cartesianPosition));
            }
        }
        this.options.getBufferInfo(bufferInfo) //把节点信息传送到上层
    }

    //开始编辑标记
    startMarker(bufferInfo) {
        this.typeEdit = typeEdit.makerEdit
        this.position = bufferInfo.position
        this.cartesianPosition = CesiumCartesian3.fromDegrees(bufferInfo.position.lng, bufferInfo.position.lat)
        let MarkerPrimitive = createMarker(this, bufferInfo)
        this.markerPrimitive = new MarkerPrimitive(this.cartesianPosition);
        let id = uuid.v1()
        this.uuidList = [id]
        let ControlPointPrimitive = createControlPoint(this, id)
        this.controlPointPrimitive = new ControlPointPrimitive(this.cartesianPosition);
    }

    //开始编辑线
    startPolyline(bufferInfo) {
        this.typeEdit = typeEdit.polylineEdit
        let PolylinePrimitive = createPolyLine(this, bufferInfo)
        this.polylinePrimitive = new PolylinePrimitive(this.cartesianPositions);
    }

    //开始编辑面
    startPolygon(bufferInfo) {
        this.typeEdit = typeEdit.polygonEdit
        let PolygonPrimitive = createPolygon(this, bufferInfo)
        this.polygonPrimitive = new PolygonPrimitive(this.cartesianPositions);
    }

    //结束编辑
    endEdit(e) {
        if (!this.options.editMode && !this.isStartMove) return
        if (this.isStart) {
            const obj = this.viewer.scene.pick(e.position)
            if (Cesiumdefined(obj)) {
                return
            }
        }
        if (this.typeEdit == typeEdit.noneEdit) return;
        this.isStart = false
        this.options.isEditingCallback(false);
        if (this.typeEdit == typeEdit.makerEdit) {
            this.markerPrimitive = undefined;
            this.controlPointPrimitive = undefined;
        } else {
            this.polylinePrimitive = undefined;
            this.polygonPrimitive = undefined;
            this.controlPointPrimitiveList = []
            this.positions = []
            this.cartesianPositions = [];
        }
        this.typeEdit = typeEdit.noneEdit
        this.deleteControlPointList()
    }

    //设置控制点的颜色
    setControlPointColor(e) {
        if (this.isMove) return;
        if (this.controlPointEntity) {
            this.controlPointEntity._point._color._value = CesiumColor.LIME;
            this.isStartMove = false;
        }
        const obj = this.viewer.scene.pick(e.endPosition)
        if (Cesiumdefined(obj) && obj.id.controlPoint == true) {
            this.isStartMove = true;
            this.controlPointEntity = obj.id;
            var selectedColor = new CesiumColor(1, 1, 0, 1);
            this.controlPointEntity._point._color._value = selectedColor;
        }
    }

    //删除控制点
    deleteControlPointList() {
        this.uuidList.forEach(element => {
            this.viewer.entities.remove(this.viewer.entities.getById(element))
        });
    }

    initEditStyle(entity) {
        let bufferInfo = entity.bufferInfo;
        if (bufferInfo.type == 'marker') {
            this.initMarkerStyle(bufferInfo, entity)
        } else if (bufferInfo.type == 'polyline') {
            this.initPolylineStyle(bufferInfo, entity)
        } else if (bufferInfo.type == 'polygon') {
            this.initPolygonStyle(bufferInfo, entity)
        }
        return bufferInfo;
    }

    initMarkerStyle(bufferInfo, entity) {
        bufferInfo.options.markerStyle.billboard.show = entity._billboard._show._value;
        bufferInfo.options.markerStyle.billboard.image = entity._billboard._image._value;
        bufferInfo.options.markerStyle.billboard.color = entity._billboard._color._value;
        bufferInfo.options.markerStyle.billboard.scale = entity._billboard._scale._value;
        bufferInfo.options.markerStyle.billboard.pixelOffset.x = entity._billboard._pixelOffset._value.x;
        bufferInfo.options.markerStyle.billboard.pixelOffset.y = entity._billboard._pixelOffset._value.y;
        bufferInfo.options.markerStyle.label.text = entity._label._text._value;
        bufferInfo.options.markerStyle.label.font = entity._label._font._value;
        bufferInfo.options.markerStyle.label.fillColor = entity._label._fillColor._value;
        bufferInfo.options.markerStyle.label.pixelOffset.x = entity._label._pixelOffset._value.x;
        bufferInfo.options.markerStyle.label.pixelOffset.y = entity._label._pixelOffset._value.y;
        bufferInfo.options.markerStyle.label.showBackground = entity._label._showBackground._value;
        bufferInfo.options.markerStyle.label.backgroundColor = entity._label._backgroundColor._value;
        bufferInfo.options.markerStyle.label.scale = entity._label._scale._value;
    }

    initPolylineStyle(bufferInfo, entity) {
        bufferInfo.options.polylineStyle.polyline.show = entity._polyline._show._value;
        bufferInfo.options.polylineStyle.polyline.material = entity._polyline._material._color._value;
        bufferInfo.options.polylineStyle.polyline.width = entity._polyline._width._value;
    }

    initPolygonStyle(bufferInfo, entity) {
        bufferInfo.options.polygonStyle.polygon.show = entity._polygon._show._value;
        bufferInfo.options.polygonStyle.polygon.fill = entity._polygon._fill._value;
        bufferInfo.options.polygonStyle.polygon.material = entity._polygon._material._color._value;
        bufferInfo.options.polygonStyle.polygon.outline = entity._polygon._outline._value;
        bufferInfo.options.polygonStyle.polygon.outlineColor = entity._polygon._outlineColor._value;
        bufferInfo.options.polygonStyle.polygon.outlineWidth = entity._polygon._outlineWidth._value;
    }
}

export {
    EditGraphic
}