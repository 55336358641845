<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    methods: {}
};
</script>
<style>
* {
    padding: 0;
    margin: 0;
}
html,
body {
    width: 100%;
    height: 100%;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
}
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.split-line {
    width: 90%;
    margin-left: 5%;
    height: 1px;
    background: #aaa;
}
.split-item-line {
    width: 100%;
    height: 1px;
    background: rgb(80, 80, 80);
}
</style>
