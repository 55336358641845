<template>
    <div class="left-navigation">
        <div
            class="fold-aside"
            @click="isShowAside = !isShowAside"
            :class="{ 'close-aside': !isShowAside }"
            :title="isShowAside ? '隐藏侧边栏' : '显示侧边栏'"
        >
            <i
                class="ivu-icon"
                :class="[
                    isShowAside
                        ? 'ivu-icon-ios-arrow-back'
                        : 'ivu-icon-ios-arrow-forward'
                ]"
                style="font-size: 1.8em;"
            ></i>
        </div>
        <el-aside
            v-show="isShowAside"
            :width="
                isShowDlg
                    ? curIndex > 0 && curIndex < 3 && isSmallScreen
                        ? '120px'
                        : '330px'
                    : '60px'
            "
        >
            <div class="aside-content">
                <div class="left-aside">
                    <ul>
                        <li v-for="(item, index) in tools" :key="index">
                            <div
                                class="out-border-aside"
                                :class="{
                                    'out-border-aside-active': item.active
                                }"
                            >
                                <div
                                    class="aside-button"
                                    @click="openCurDlg(index, item)"
                                >
                                    <div>
                                        <i
                                            :class="item.icon"
                                            style="font-size: 1.6em;"
                                        ></i>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 弹出对话框 -->
                <div
                    v-if="isShowDlg"
                    class="right-aside self-aside-dlg"
                    :class="{
                        'right-aside-small':
                            curIndex > 0 && curIndex < 3 && isSmallScreen
                    }"
                >
                    <div class="title-head">
                        <span>{{ tools[curIndex].name }}</span>
                        <span
                            class="close-dlg"
                            :class="{
                                'close-dlg-small':
                                    curIndex > 0 &&
                                    curIndex < 3 &&
                                    isSmallScreen
                            }"
                            ><i class="el-icon-close" @click="closeDlg"></i
                        ></span>
                    </div>
                    <div class="split-line"></div>
                    <div
                        class="contain-body scrollbar-div"
                        :style="'height:' + heightAside + 'px;'"
                    >
                        <div class="scrollbar">
                            <keep-alive>
                                <component
                                    :isSmallScreen="isSmallScreen"
                                    :is="currentTabComponent"
                                ></component>
                            </keep-alive>
                        </div>
                    </div>
                </div>
            </div>
        </el-aside>
    </div>
</template>

<script>
import BaseLayer from "./BaseLayer.vue";
import DrawHandle from "./draw/DrawHandle.vue";
import ViewPoint from "./ViewPoint.vue";
import Tool from "./tool/Tool.vue";
import Setting from "./Setting.vue";
export default {
    name: "LeftNavigation",
    components: {
        BaseLayer,
        DrawHandle,
        ViewPoint,
        Tool,
        Setting
    },
    props: {
        heightAside: {
            type: Number,
            default: 100
        },
        widthApp: {
            type: Number,
            default: 600
        }
    },
    data() {
        return {
            tools: [
                {
                    id: 1,
                    name: "地图",
                    icon: "ivu-icon ivu-icon-md-map",
                    active: false,
                    component: "BaseLayer"
                },
                {
                    id: 2,
                    name: "标绘",
                    icon: "el-icon-edit-outline",
                    active: false,
                    component: "DrawHandle"
                },
                // {
                //     id: 3,
                //     name: "视点",
                //     icon: "ivu-icon ivu-icon-ios-eye",
                //     active: false,
                //     component: "ViewPoint"
                // },
                {
                    id: 4,
                    name: "工具",
                    icon: "ivu-icon ivu-icon-ios-hammer-outline",
                    active: false,
                    component: "Tool"
                },
                {
                    id: 5,
                    name: "设置",
                    icon: "el-icon-s-tools",
                    active: false,
                    component: "Setting"
                }
            ],
            currentTabComponent: "isBaseLayer",
            curIndex: 0, //当前选择项
            isShowDlg: false,
            isShowAside: true,
            isSmallScreen: false
        };
    },
    mounted() {},
    methods: {
        openCurDlg(index, item) {
            if (item.active) {
                item.active = false;
            } else {
                for (let it of this.tools) {
                    it.active = it.id == item.id;
                }
            }
            this.isShowDlg = item.active;
            if (this.isShowDlg) {
                this.curIndex = index;
                this.currentTabComponent = item.component;
            }
        },
        closeDlg() {
            this.isShowDlg = false;
            for (let it of this.tools) {
                it.active = false;
            }
        }
    },
    watch: {
        widthApp(val) {
            if (val < 600) {
                this.isSmallScreen = true;
            } else {
                this.isSmallScreen = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../assets/css/selfAsideDlg.less";
.fold-aside {
    width: 60px;
    margin-top: 5px;
    text-align: center;
    position: absolute;
    float: left;
    cursor: pointer;
    i {
        color: #ffffff;
        border: 2px solid rgba(0, 0, 0, 0);
    }
}

.close-aside {
    width: 30px;
}
.left-navigation {
    height: 100%;
    z-index: 5;
}

.el-aside {
    background: rgba(50, 50, 50, 0);
    color: #ffffff;
    height: 100%;
}

.aside-content {
    width: 100%;
    height: 100%;
    display: flex;
}

.left-aside {
    width: 60px;
    height: 100%;
    background: #333;
    ul {
        margin-top: 30px;
        display: inline-block;
        li {
            padding-top: 10px;
        }
    }
}

.split-block {
    width: 100%;
    height: 10px;
}

.out-border-aside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-left: 5px;
    border-radius: 5px;

    .aside-button {
        width: 40px;
        height: 40px;
        text-align: center;
        cursor: pointer;
    }
}

.out-border-aside:hover,
.fold-aside i:hover {
    border: 2px solid #409eff;
    box-shadow: 0 0 8px #9bc8f7;
    -moz-box-shadow: 0 0 8px #9bc8f7;
    -webkit-box-shadow: 0 0 8px #9bc8f7;
}

.out-border-aside-active {
    background: #409eff;
}

.right-aside {
    width: 270px;
}
.right-aside-small {
    width: 60px;
}
</style>
