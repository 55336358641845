var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-navigation"},[_c('div',{staticClass:"fold-aside",class:{ 'close-aside': !_vm.isShowAside },attrs:{"title":_vm.isShowAside ? '隐藏侧边栏' : '显示侧边栏'},on:{"click":function($event){_vm.isShowAside = !_vm.isShowAside}}},[_c('i',{staticClass:"ivu-icon",class:[
                _vm.isShowAside
                    ? 'ivu-icon-ios-arrow-back'
                    : 'ivu-icon-ios-arrow-forward'
            ],staticStyle:{"font-size":"1.8em"}})]),_c('el-aside',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAside),expression:"isShowAside"}],attrs:{"width":_vm.isShowDlg
                ? _vm.curIndex > 0 && _vm.curIndex < 3 && _vm.isSmallScreen
                    ? '120px'
                    : '330px'
                : '60px'}},[_c('div',{staticClass:"aside-content"},[_c('div',{staticClass:"left-aside"},[_c('ul',_vm._l((_vm.tools),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"out-border-aside",class:{
                                'out-border-aside-active': item.active
                            }},[_c('div',{staticClass:"aside-button",on:{"click":function($event){return _vm.openCurDlg(index, item)}}},[_c('div',[_c('i',{class:item.icon,staticStyle:{"font-size":"1.6em"}})]),_c('div',[_vm._v(_vm._s(item.name))])])])])}),0)]),(_vm.isShowDlg)?_c('div',{staticClass:"right-aside self-aside-dlg",class:{
                    'right-aside-small':
                        _vm.curIndex > 0 && _vm.curIndex < 3 && _vm.isSmallScreen
                }},[_c('div',{staticClass:"title-head"},[_c('span',[_vm._v(_vm._s(_vm.tools[_vm.curIndex].name))]),_c('span',{staticClass:"close-dlg",class:{
                            'close-dlg-small':
                                _vm.curIndex > 0 &&
                                _vm.curIndex < 3 &&
                                _vm.isSmallScreen
                        }},[_c('i',{staticClass:"el-icon-close",on:{"click":_vm.closeDlg}})])]),_c('div',{staticClass:"split-line"}),_c('div',{staticClass:"contain-body scrollbar-div",style:('height:' + _vm.heightAside + 'px;')},[_c('div',{staticClass:"scrollbar"},[_c('keep-alive',[_c(_vm.currentTabComponent,{tag:"component",attrs:{"isSmallScreen":_vm.isSmallScreen}})],1)],1)])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }