<template>
    <div>
        <div class="style-item">
            <!-- 面标记是否显示 -->
            <div>
                <Checkbox
                    v-model="checkPolygon"
                    @click.native="setPolygonVisible"
                    style="color:#fff"
                    >面标记</Checkbox
                >
            </div>
            <!-- 填充是否开启 -->
            <div class="child-item">
                <Checkbox
                    v-model="checkFill"
                    @click.native="setCheckFill"
                    style="color:#fff"
                    >填充</Checkbox
                >
            </div>
            <!-- 填充颜色 -->
            <SelfColorPicker
                title="填充"
                :curColor="polygonFillColor"
                @func="changePolygonFillColor"
            />

            <!-- 边框是否开启 -->
            <!-- <div class="child-item">
                <Checkbox
                    v-model="checkOutline"
                    @click.native="setCheckOutline"
                    style="color:#fff"
                    >边框</Checkbox
                >
            </div> -->
            <!-- 填充颜色 -->
            <!-- <SelfColorPicker
                title="边框"
                :curColor="polygonOutlineColor"
                @func="changePolygonOutlineColor"
            /> -->

            <!-- 线的宽度 -->
            <!-- <SelfRange
                title="线宽"
                :minVal="1"
                :maxVal="20"
                :curValue="outlineWidth"
                @func="changeOutlineWidth"
            /> -->
        </div>
    </div>
</template>


<script>
import common from "@/assets/js/common.js";
import SelfColorPicker from "./SelfColorPicker.vue";
import SelfRange from "./SelfRange.vue";
export default {
    name: "PolygonStyle",
    components: {
        SelfColorPicker,
        SelfRange
    },
    props: {
        entity: {
            type: Object,
            default: {}
        },
        bufferInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            checkPolygon: true,
            checkFill: true,
            polygonFillColor: "",
            checkOutline: true,
            polygonOutlineColor: "",
            outlineWidth: 2
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initData();
        });
    },
    methods: {
        initData() {
            let polygon = this.entity._polygon;
            this.checkPolygon = polygon._show._value;
            this.checkFill = polygon._fill._value;
            this.polygonFillColor = common.cesiumColorToRgba(
                polygon._material._color._value
            );
            this.checkOutline = polygon._outline._value;
            this.polygonOutlineColor = common.cesiumColorToRgba(
                polygon._outlineColor._value
            );
            this.outlineWidth = polygon._outlineWidth._value;
        },
        setPolygonVisible() {
            let show = !this.checkPolygon;
            this.bufferInfo.options.polygonStyle.polygon.show = show;
            this.entity._polygon._show._value = show;
        },
        setCheckFill() {
            let fill = !this.checkFill;
            this.bufferInfo.options.polygonStyle.polygon.fill = fill;
            this.entity._polygon._fill._value = fill;
        },
        changePolygonFillColor(e) {
            this.bufferInfo.options.polygonStyle.polygon.material = e;
            this.entity._polygon._material._color._value = e;
        },
        setCheckOutline() {
            let outline = !this.checkOutline;
            this.bufferInfo.options.polygonStyle.polygon.outline = outline;
            this.entity._polygon._outline._value = outline;
        },
        changePolygonOutlineColor(e) {
            this.bufferInfo.options.polygonStyle.polygon.outlineColor = e;
            this.entity._polygon._outlineColor._value = e;
        },
        changeOutlineWidth(e) {
            this.bufferInfo.options.polygonStyle.polygon.outlineWidth = e;
            this.entity._polygon._outlineWidth._value = e;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/selfAsideDlg.less";
@import "../../../assets/css/chileStyleItem.scss";
</style>