<template>
    <div>
        <MeasureTool :isSmallScreen="isSmallScreen" />
        <WeatherTool :isSmallScreen="isSmallScreen" />
    </div>
</template>

<script>
import MeasureTool from "./MeasureTool.vue";
import WeatherTool from "./WeatherTool.vue";
export default {
    props: {
        isSmallScreen: {
            type: Boolean,
            default: true
        }
    },
    components: {
        MeasureTool,
        WeatherTool
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="scss" scoped>
</style>