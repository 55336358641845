<template>
    <div class="child-item">
        <span class="title">{{ title }}</span>
        <Slider
            style="width:65%"
            v-model="value"
            :min="minVal"
            :max="maxVal"
            @on-change="changevalue"
        ></Slider>
        <span class="tail">{{ value / scale }}{{ tail }}</span>
    </div>
</template>
<script>
import common from "@/assets/js/common.js";
export default {
    props: {
        title: {
            type: String,
            default: "范围"
        },
        curValue: {
            type: Number,
            default: 50
        },
        scale: {
            type: Number,
            default: 1
        },
        tail: {
            type: String,
            default: ""
        },
        maxVal: {
            type: Number,
            default: 100
        },
        minVal: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            value: 50
        };
    },
    mounted() {
        this.value = this.curValue;
    },
    methods: {
        changevalue(e) {
            let curValue = e / this.scale;
            this.$emit("func", curValue);
        }
    },
    watch: {
        curValue(val) {
            this.value = val;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/chileStyleItem.scss";
</style>