/*
画面
*/
import {
    DrawHandle
} from "./drawHandle.js"

import {
    createPolygon
} from './entities'
import {
    defined as Cesiumdefined
} from "cesium/Cesium";

class Polygon extends DrawHandle {
    constructor(viewer, options = null) {
        //super关键字等价于父类构造方法
        super(viewer, options);
        this.polygon = undefined;
    }
    onLeftClick(e) {
        if (!super.onLeftClick(e)) return false;
        if (this.options.controlPoint > 0 && (this.options.controlPoint + 1) == this.positions.length) {
            this.positions.splice(this.positions.length - 1, 1)
            this.clearPoints()
            this.polygon = undefined;
        }
        return true;
    }
    onMove(e) {
        if (!super.onMove(e)) return false;
        this.addPointToPolygon()
        return true;
    }
    onDoubleClick(e) {
        if (!super.onDoubleClick(e)) return false;
        this.polygon = undefined;
        return true;
    }
    onRightClick(e) {
        this.polygon = undefined;
        super.onRightClick(e)
    }
    endDrawHandle() {
        super.endDrawHandle()
        if (this.polygon) {
            this.polygon = undefined;
            this.clearPoints();
        }
    }
    addPointToPolygon() {
        this.getGeneratePoints(this.options);
        let PolygonPrimitive = createPolygon(this)
        if (!Cesiumdefined(this.polygon)) {
            this.polygon = new PolygonPrimitive(this.cartesianPositions);
        }
    }
}

export {
    Polygon
}