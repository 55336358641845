var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pop-up"},[_c('div',{staticClass:"title"},[_vm._v("点标记")]),_c('div',{staticClass:"box-list"},_vm._l((_vm.pointList),function(item,index){return _c('div',{key:index,staticClass:"box",class:{
                    'active-box': item.active,
                    'box-small': _vm.isSmallScreen
                },on:{"click":function($event){return _vm.darwPoint(item)}}},[_c('div',{staticClass:"img-flag",attrs:{"title":item.title}},[_c('img',{attrs:{"src":item.img}})])])}),0)]),_c('div',{staticClass:"split-item-line"}),_c('div',{staticClass:"pop-up"},[_c('div',{staticClass:"title"},[_vm._v("线标记")]),_c('div',{staticClass:"box-list"},_vm._l((_vm.lineList),function(item,index){return _c('div',{key:index,staticClass:"box",class:{
                    'active-box': item.active,
                    'box-small': _vm.isSmallScreen
                },on:{"click":function($event){return _vm.darwLine(item)}}},[_c('div',{staticClass:"img-flag",attrs:{"title":item.title}},[_c('img',{attrs:{"src":item.img}})])])}),0)]),_c('div',{staticClass:"split-item-line"}),_c('div',{staticClass:"pop-up"},[_c('div',{staticClass:"title"},[_vm._v("面标记")]),_c('div',{staticClass:"box-list"},_vm._l((_vm.areaList),function(item,index){return _c('div',{key:index,staticClass:"box",class:{
                    'active-box': item.active,
                    'box-small': _vm.isSmallScreen
                },on:{"click":function($event){return _vm.darwArea(item)}}},[_c('div',{staticClass:"img-flag",attrs:{"title":item.title}},[_c('img',{attrs:{"src":item.img}})])])}),0)]),_c('div',{staticClass:"split-item-line"}),_c('div',{staticClass:"pop-up"})])}
var staticRenderFns = []

export { render, staticRenderFns }