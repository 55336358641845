<template>
  <div class="page-body">
    <div class="foot">
      <span
        >2022.晟兴科技
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >京ICP备15037822号-2</a
        ></span
      >
    </div>
    <earthViewer></earthViewer>
    <showBottomInfo></showBottomInfo>
  </div>
</template>

<script>
import earthViewer from "@/components/map/CesiumContainer";
import showBottomInfo from "@/components/map/ShowBottomInfo";
export default {
  name: "Index",
  components: {
    earthViewer,
    showBottomInfo,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.page-body {
  width: 100%;
  height: 100%;
}
.foot {
  height: 30px;
  width: 100%;
  background-color: #ddd0;
  position: fixed;
  bottom: 0;
  z-index: 100;
  span {
    margin-left: 100px;
  }
}
</style>
