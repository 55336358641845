<template>
    <div>
        <!-- 图标配置 -->
        <div class="style-item">
            <div>
                <Checkbox
                    v-model="checkImg"
                    @click.native="setImgVisible"
                    style="color:#fff"
                    >图标配置</Checkbox
                >
            </div>
            <!-- 图标列表 -->
            <div class="contain-body scrollbar-div item-scroll">
                <div class="scrollbar">
                    <div class="img-list">
                        <div v-for="(item, index) in markerNum" :key="index">
                            <img
                                @click="changeImg(item)"
                                :class="{ 'active-img': item == curImgIndex }"
                                :src="markerUrl + item + '.png'"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 图标颜色 -->
            <SelfColorPicker
                :curColor="markerColor"
                @func="changeMarkerColor"
            />
            <!-- 图标尺寸 -->
            <SelfRange
                title="缩放"
                :scale="10"
                :curValue="markerScale"
                @func="changeMarkerScale"
            />
            <!-- 偏移 -->
            <SelfPixelOffset
                :minVal="-200"
                :maxVal="200"
                :curPixelOffsetX="pixelOffsetX"
                :curPixelOffsetY="pixelOffsetY"
                @funcX="changePixelOffsetX"
                @funcY="changePixelOffsetY"
            />
        </div>
        <div class="split-item-line"></div>
        <div class="style-item">
            <div>
                <Checkbox
                    v-model="checkImg"
                    @click.native="setImgVisible"
                    style="color:#fff"
                    >文字配置</Checkbox
                >
            </div>
            <!-- 文字内容 -->
            <div class="child-item">
                <span class="title">名称</span>
                <Input
                    v-model="labelText"
                    placeholder="请填写名称"
                    style="width: 200px"
                    @on-change="changeLabelText"
                />
            </div>
            <!-- 文本字号 -->
            <SelfRange
                title="字号"
                tail="px"
                :minVal="8"
                :maxVal="99"
                :curValue="labelFontSize"
                @func="changeFontSize"
            />
            <!-- 字体 -->
            <div class="child-item">
                <span class="title">字体</span>
                <Select
                    v-model="labelFontFamily"
                    style="width:200px"
                    @on-change="chooseFontFamily"
                >
                    <Option
                        v-for="item in fontFamilyList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                    >
                </Select>
            </div>
            <!-- 字体样式 -->
            <div class="child-item" style="height:10px;">
                <Checkbox
                    v-model="checkFontItalic"
                    @click.native="setFontItalic"
                    style="color:#fff;margin-left:40px;"
                    >倾斜</Checkbox
                >
                <Checkbox
                    v-model="checkFontWeight"
                    @click.native="setFontWeight"
                    style="color:#fff"
                    >加粗</Checkbox
                >
            </div>

            <!-- 文字缩放 -->
            <SelfRange
                title="缩放"
                :scale="10"
                :curValue="labelScale"
                @func="changeLabelScale"
            />

            <!-- 文字颜色 -->
            <SelfColorPicker
                :curColor="labelTextColor"
                @func="changeLabelTextColor"
            />
            <!-- 偏移 -->
            <SelfPixelOffset
                :minVal="-200"
                :maxVal="200"
                :curPixelOffsetX="labelPixelOffsetX"
                :curPixelOffsetY="labelPixelOffsetY"
                @funcX="changeLabelPixelOffsetX"
                @funcY="changeLabelPixelOffsetY"
            />
            <!-- 背景是否开启 -->
            <div class="child-item">
                <Checkbox
                    v-model="labelShowBackground"
                    @click.native="setLabelShowBackground"
                    style="color:#fff"
                    >背景</Checkbox
                >
            </div>
            <!-- 背景颜色 -->
            <SelfColorPicker
                title="背景"
                :curColor="labelBackgroundColor"
                @func="changeLabelBackgroundColor"
            />
        </div>
    </div>
</template>

<script>
import common from "@/assets/js/common.js";
import SelfColorPicker from "./SelfColorPicker.vue";
import SelfRange from "./SelfRange.vue";
import SelfPixelOffset from "./SelfPixelOffset.vue";
export default {
    name: "MarkerStyle",
    components: {
        SelfColorPicker,
        SelfRange,
        SelfPixelOffset
    },
    props: {
        entity: {
            type: Object,
            default: {}
        },
        bufferInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            checkImg: true,
            markerNum: 31,
            markerUrl: "./images/marker/",
            curImgIndex: 1,
            markerColor: "rgba(25, 190,107, 1.0)",
            markerColorList: [
                "rgba(24, 93, 178,1.0)",
                "rgba(70, 106, 216,1.0)",
                "rgba(208, 135, 19,1.0)",
                "rgba(232, 81, 145,1.0)",
                "rgba(131, 182, 230,1.0)",
                "rgba(32, 176, 32,1.0)"
            ],
            markerScale: 10,
            pixelOffsetX: 0,
            pixelOffsetY: 0,
            labelText: "",
            labelFontSize: 14,
            labelFontFamily: "宋体",
            fontFamilyList: [
                {
                    value: "宋体",
                    label: "宋体"
                },
                {
                    value: "微软雅黑",
                    label: "微软雅黑"
                },
                {
                    value: "黑体",
                    label: "黑体"
                },
                {
                    value: "楷体",
                    label: "楷体"
                }
            ],
            checkFontItalic: false,
            checkFontWeight: false,
            labelTextColor: "rgba(25, 190,107, 1.0)",
            labelPixelOffsetX: 0,
            labelPixelOffsetY: 0,
            labelShowBackground: false,
            labelBackgroundColor: "rgba(25, 190,107, 1.0)",
            labelScale: 10
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initData();
        });
    },
    methods: {
        initData() {
            this.initBillboard();
            this.initLabel();
        },
        initBillboard() {
            let billboard = this.entity._billboard;
            let url = billboard._image._value;
            let list = url.split("/");
            let name = list[list.length - 1];
            list = name.split(".");
            this.curImgIndex = list[0];
            this.checkImg = billboard._show._value;
            this.markerColor = common.cesiumColorToRgba(
                billboard._color._value
            );
            this.markerScale = billboard._scale._value * 10;
            this.pixelOffsetX = billboard._pixelOffset._value.x;
            this.pixelOffsetY = billboard._pixelOffset._value.y;
        },
        initLabel() {
            let label = this.entity._label;
            this.labelText = label._text._value;
            let list = this.entity._label._font._value.split(" ");
            this.checkFontItalic = list[0] != "normal";
            this.checkFontWeight = list[1] != "normal";
            this.labelFontSize = parseInt(list[2]);
            this.labelFontFamily = list[3];
            this.labelTextColor = common.cesiumColorToRgba(
                label._fillColor._value
            );
            this.labelPixelOffsetX = label._pixelOffset._value.x;
            this.labelPixelOffsetY = label._pixelOffset._value.y;
            this.labelShowBackground = label._showBackground._value;
            this.labelBackgroundColor = common.cesiumColorToRgba(
                label._backgroundColor._value
            );
            this.labelScale = label._scale._value * 10;
        },
        setImgVisible() {
            let show = !this.checkImg;
            this.bufferInfo.options.markerStyle.billboard.show = show;
            this.entity._billboard._show._value = show;
        },
        changeImg(item) {
            this.curImgIndex = item;
            let url = this.markerUrl + item + ".png";
            this.bufferInfo.options.markerStyle.billboard.image = url;
            this.entity._billboard._image._value = url;
        },
        changeMarkerColor(e) {
            this.bufferInfo.options.markerStyle.billboard.color = e;
            this.entity._billboard._color._value = e;
        },
        changeMarkerScale(e) {
            this.bufferInfo.options.markerStyle.billboard.scale = e;
            this.entity._billboard._scale._value = e;
        },
        changePixelOffsetX(e) {
            this.bufferInfo.options.markerStyle.billboard.pixelOffset.x = e;
            this.entity._billboard._pixelOffset._value.x = e;
        },
        changePixelOffsetY(e) {
            this.bufferInfo.options.markerStyle.billboard.pixelOffset.y = e;
            this.entity._billboard._pixelOffset._value.y = e;
        },
        changeLabelText() {
            this.bufferInfo.options.markerStyle.label.text = this.labelText;
            this.entity._label._text._value = this.labelText;
        },
        changeFontSize(e) {
            this.labelFontSize = e;
            this.setFont();
        },
        setFontItalic() {
            this.checkFontItalic = !this.checkFontItalic;
            this.setFont();
        },
        setFontWeight() {
            this.checkFontWeight = !this.checkFontWeight;
            this.setFont();
        },
        chooseFontFamily(e) {
            this.labelFontFamily = e;
            this.setFont();
        },
        setFont() {
            let font = `${this.checkFontItalic ? "italic" : "normal"} ${
                this.checkFontWeight ? "bold" : "normal"
            } ${this.labelFontSize}px ${this.labelFontFamily}`;

            this.bufferInfo.options.markerStyle.label.font = font;
            this.entity._label._font._value = font;
        },
        changeLabelTextColor(e) {
            this.bufferInfo.options.markerStyle.label.fillColor = e;
            this.entity._label._fillColor._value = e;
        },
        changeLabelPixelOffsetX(e) {
            this.bufferInfo.options.markerStyle.label.pixelOffset.x = e;
            this.entity._label._pixelOffset._value.x = e;
        },
        changeLabelPixelOffsetY(e) {
            this.bufferInfo.options.markerStyle.label.pixelOffset.y = e;
            this.entity._label._pixelOffset._value.y = e;
        },
        setLabelShowBackground() {
            this.labelShowBackground = !this.labelShowBackground;
            this.bufferInfo.options.markerStyle.label.showBackground = this.labelShowBackground;
            this.entity._label._showBackground._value = this.labelShowBackground;
        },
        changeLabelBackgroundColor(e) {
            this.bufferInfo.options.markerStyle.label.backgroundColor = e;
            this.entity._label._backgroundColor._value = e;
        },
        changeLabelScale(e) {
            this.bufferInfo.options.markerStyle.label.scale = e;
            this.entity._label._scale._value = e;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/selfAsideDlg.less";
@import "../../../assets/css/chileStyleItem.scss";
.img-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    div {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            cursor: pointer;
            height: 80%;
            border-radius: 10px;
            border: double 4px rgba(0, 0, 0, 0);
        }
        .active-img {
            border: double 4px #bdecf8;
        }
        img:hover {
            border: 2px solid #bdecf8;
            box-shadow: 0 0 8px #e1f1f5;
            -moz-box-shadow: 0 0 8px #e1f1f5;
            -webkit-box-shadow: 0 0 8px #e1f1f5;
        }
    }
}
</style>