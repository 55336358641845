/*
鼠标事件的基类
*/
import {
    generateCurve,
    generateArc,
    generateArrowStraight,
    generateRectangle,
    generateCircle,
    generateEllipse,
    generateLune,
    generateSector,
    generateClosedCurve,
    generateGather,
    generateArrawAssaultDirection,
    generateArrawAttack,
    generateArrawAttackTailled,
    generateArrawFine,
    generateArrawSquadCambat,
    generateArrawTailedSquadCambat,
    generateDoubleArraw,
    generateThreeArrowLine
} from './drawGraphic.js'

import {
    ScreenSpaceEventHandler as CesiumScreenSpaceEventHandler,
    ScreenSpaceEventType as CesiumScreenSpaceEventType,
    Cartesian3 as CesiumCartesian3,
    defined as Cesiumdefined,
    Math as CesiumMath
} from "cesium/Cesium";

class MouseHandle {
    constructor(viewer, options) {
            this.options = options //一些操作		
            this.viewer = viewer
            this.initVal()
        }
        //初始化参数
    initVal() {
            if (!this.options) {
                this.options = {
                    endDraw: true,
                    editMode: false
                }
            } else {
                if (!this.options.hasOwnProperty('endDraw'))
                    this.options.endDraw = true
                if (!this.options.hasOwnProperty('editMode'))
                    this.options.editMode = false
            }
            this.positions = [] //经纬度控制点
            this.cartesianPositions = []; //xyz控制点
            this.handler = new CesiumScreenSpaceEventHandler(this.viewer.scene.canvas) //鼠标事件
            this.initMouseHandle() //初始化鼠标事件
        }
        //初始化鼠标事件
    initMouseHandle() {
        //鼠标左键点击事件
        this.handler.setInputAction(e => {
            this.onLeftClick(e)
        }, CesiumScreenSpaceEventType.LEFT_CLICK);
        //左键双击事件
        this.handler.setInputAction(e => {
            this.onDoubleClick(e)
        }, CesiumScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        //左键按下事件
        this.handler.setInputAction(e => {
            this.onLeftClickDown(e)
        }, CesiumScreenSpaceEventType.LEFT_DOWN);
        //左键弹起事件
        this.handler.setInputAction(e => {
            this.onLeftClickUp(e)
        }, CesiumScreenSpaceEventType.LEFT_UP);
        //鼠标中键单击事件
        this.handler.setInputAction(e => {
            this.onMiddleClick(e)
        }, CesiumScreenSpaceEventType.MIDDLE_CLICK);
        //鼠标中键按下事件
        this.handler.setInputAction(e => {
            this.onMiddleClickDown(e)
        }, CesiumScreenSpaceEventType.MIDDLE_DOWN);
        //鼠标中键弹起事件
        this.handler.setInputAction(e => {
            this.onMiddleClickUp(e)
        }, CesiumScreenSpaceEventType.MIDDLE_UP);
        //鼠标右键点击事件
        this.handler.setInputAction(e => {
            this.onRightClick(e)
        }, CesiumScreenSpaceEventType.RIGHT_CLICK);
        //鼠标中键按下事件
        this.handler.setInputAction(e => {
            this.onRightClickDown(e)
        }, CesiumScreenSpaceEventType.RIGHT_DOWN);
        //鼠标中键弹起事件
        this.handler.setInputAction(e => {
            this.onRightClickUp(e)
        }, CesiumScreenSpaceEventType.RIGHT_UP);
        //鼠标移动事件
        this.handler.setInputAction(e => {
            this.onMove(e)
        }, CesiumScreenSpaceEventType.MOUSE_MOVE);
        //鼠标滚轮
        this.handler.setInputAction(e => {
            this.onWheel(e)
        }, CesiumScreenSpaceEventType.WHEEL);
    }
    onLeftClick(e) {}
    onLeftClickDown(e) {}
    onLeftClickUp(e) {}
    onMiddleClick(e) {}
    onMiddleClickDown(e) {}
    onMiddleClickUp(e) {}
    onRightClick(e) {}
    onRightClickDown(e) {}
    onRightClickUp(e) {}
    onMove(e) {}
    onDoubleClick(e) {}
    onWheel(e) {}

    setOptions(options) {
        this.options = options
        if (options.endDraw) {
            this.positions = []
            this.handler.destroy();
        }
    }
    getMousePoint(e, mouseType = '') {
            let scene = this.viewer.scene;
            let ellipsoid = scene.globe.ellipsoid;
            //通过指定的椭球或者地图对应的坐标系，将鼠标的二维坐标转换为对应椭球体三维坐标
            let cartesian = this.viewer.camera.pickEllipsoid(mouseType == 'move' ? e.endPosition : e.position, ellipsoid);
            if (cartesian) {
                //将笛卡尔坐标转换为地理坐标
                let cartographic = ellipsoid.cartesianToCartographic(cartesian);
                //将弧度转为度的十进制度表示
                let lng = CesiumMath.toDegrees(cartographic.longitude);
                let lat = CesiumMath.toDegrees(cartographic.latitude);
                //海拔高度
                //alt = cartographic.height;
                let alt = Math.ceil(scene.globe.getHeight(cartographic));
                return {
                    lng,
                    lat,
                    alt
                }
            }
            return null
        }
        //经纬度转Cesium坐标
    degreeLLH2XYZ(points) {
        let pts = []
        for (let point of points) {
            pts.push(CesiumCartesian3.fromDegrees(point.lng, point.lat, 0))
        }
        return pts;
    }

    //生成cartesianPositions
    generateCartesianPositions(positions) {
        let cartesianPositions = this.degreeLLH2XYZ(positions)
        this.cartesianPositions.splice(0, this.cartesianPositions.length);
        for (let item of cartesianPositions) {
            this.cartesianPositions.push(item)
        }
    }

    getGeneratePoints(options) {
        let positions = this.positions;
        //线标记
        if (options.hasOwnProperty('lineStyle') && options.lineStyle != '') {
            if (options.lineStyle == 'curve') positions = generateCurve(this.positions);
            else if (options.lineStyle == 'arc') positions = generateArc(this.positions);
            else if (options.lineStyle == 'arrow_straight') positions = generateArrowStraight(this.positions);
        }
        //面标记
        else if (options.hasOwnProperty('polyStyle') && options.polyStyle != '') {
            if (options.polyStyle == 'rectangle') positions = generateRectangle(this.positions);
            else if (options.polyStyle == 'circle') positions = generateCircle(this.positions);
            else if (options.polyStyle == 'ellipse') positions = generateEllipse(this.positions);
            else if (options.polyStyle == 'lune') positions = generateLune(this.positions);
            else if (options.polyStyle == 'sector') positions = generateSector(this.positions);
            else if (options.polyStyle == 'curve_close_area') positions = generateClosedCurve(this.positions);
            else if (options.polyStyle == 'gather') positions = generateGather(this.positions);
            else if (options.polyStyle == 'arraw_assault_direction') positions = generateArrawAssaultDirection(this.positions);
            else if (options.polyStyle == 'arraw_attack') positions = generateArrawAttack(this.positions);
            else if (options.polyStyle == 'arraw_attack_tailled') positions = generateArrawAttackTailled(this.positions);
            else if (options.polyStyle == 'arraw_fine') positions = generateArrawFine(this.positions);
            else if (options.polyStyle == 'arraw_squad_cambat') positions = generateArrawSquadCambat(this.positions);
            else if (options.polyStyle == 'arraw_tailed_squad_cambat') positions = generateArrawTailedSquadCambat(this.positions);
            else if (options.polyStyle == 'double_arraw') positions = generateDoubleArraw(this.positions);
            else if (options.polyStyle == 'three_arrow') positions = generateThreeArrowLine(this.positions);
        }
        this.generateCartesianPositions(positions)
    }
}

export {
    MouseHandle
}