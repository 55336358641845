/*
读本地的json文件
*/
import axios from 'axios';
import {
    Cartesian3 as CesiumCartesian3,
} from "cesium/Cesium";
const coordtransform = require('coordtransform');
const shapefile = require("shapefile");

function read(name, ids, callback) {
    let handIds = (data) => {
        let list = []
        for (let item of data) {
            item.active = ids.includes(item.id);
            if (item.id > 0) {
                list.push(item)
            }
        }
        callback(null, list);
    }

    if (process.env.NODE_ENV === 'development') { //需要注意在开发环境和正式环境 请求得url地址的区别
        axios.get('api/' + name).then((res) => {
            handIds(res.data.data.list); //这个就是json文件里面得json数据
        }, (err) => {
            callback(err, null); //这个就是json文件里面得json数据
        })
    } else {
        axios.get('/data/' + name + '.json').then((res) => {
            handIds(res.data.list)
        }, (err) => {
            callback(err, null); //这个就是json文件里面得json数据
        })
    }
}


function getMapImage(url, subdomains = "abc", x = 26, y = 12, z = 5) {
    let str = url;
    if (url.includes("{x}")) {
        str = url.replace("{x}", x);
    }
    if (url.includes("{y}")) {
        str = url.replace("{y}", y);
    }
    if (url.includes("{z}")) {
        str = url.replace("{z}", z);
    }
    if (url.includes("{s}")) {
        str = url.replace("{s}", subdomains.charAt(0));
    }
    return addOtherTemplateValues(str, x, y, z);
}

function addOtherTemplateValues(url, x, y, z) {
    let str = url;
    //sxsim add for bing
    if (url.indexOf("{bing_map}") > -1) {
        let bing = getBingQuadKey(x, y, z - 1);
        str = url.replace("{bing_map}", bing);
    }
    if (url.indexOf("{z+1}") > -1) {
        str = url.replace("{z+1}", z + 1);
    }
    if (url.indexOf("{z-1}") > -1) {
        str = url.replace("{z-1}", z - 1);
    }
    let subString = interceptString(str, "{", "}");
    while (subString.indexOf("{") > -1) {
        let key = subString.replace("{", "");
        key = key.replace("}", "");
        let val = http_url(subString, x, y, z);
        str = str.replace(subString, val);
        subString = interceptString(str, "{", "}");
    }
    return str;
}

function getBingQuadKey(tile_x, tile_y, lod) {
    let ret = "";
    for (let i = lod + 1; i > 0; i--) {
        let digit = "0";
        digit = digit.charCodeAt(); //字母转为ascii
        let mask = 1 << (i - 1);
        if ((tile_x & mask) != 0) {
            digit++;
        }
        if ((tile_y & mask) != 0) {
            digit += 2;
        }
        ret = ret + String.fromCharCode(digit);
    }
    return ret;
}
//sxsim add for 取得字符串
function interceptString(str, start, end) {
    let indexStart = str.lastIndexOf(start);
    let indexEnd = str.lastIndexOf(end);
    return str.substring(indexStart, indexEnd + 1);
}
//把js表达式替换成具体的数字
function http_url(url, x, y, z) {
    url = url.replace("{", "");
    url = url.replace("}", "");
    return eval(url);
}

//查找地名
function searchPlace(keywords, callback) {
    // https://restapi.amap.com/v3/assistant/inputtips?keywords=世奥国际&location=116.43180230827696%2C39.9420065288222&datatype=poi&key=1ce5f4aef24df9b3315632813c7fcaa2 例子
    let url = 'https://restapi.amap.com/v3/assistant/inputtips?keywords={}&location=116.43180230827696%2C39.9420065288222&datatype=poi&key=1ce5f4aef24df9b3315632813c7fcaa2'
    url = url.replace('{}', keywords)
    axios.get(url).then((res) => {
        let data = res.data.tips;
        //把火星坐标转换为wgs84
        for (let item of data) {
            item.value = item.name;
            let location = item.location;
            if (location.length == 0) continue;
            let list = location.split(',');
            let gcj02towgs84 = coordtransform.gcj02towgs84(list[0], list[1])
            item.cartesian = CesiumCartesian3.fromDegrees(gcj02towgs84[0], gcj02towgs84[1], 0)
        }
        callback(null, data); //这个就是json文件里面得json数据
    }, (err) => {
        callback(err, null); //这个就是json文件里面得json数据
    })
}

//读取shapefile文件
function radeShapefile(url, callback) {
    shapefile.open(url)
        .then(source => source.read()
            .then(function log(result) {
                if (result.done) return;
                console.log(result.value);
                //return source.read().then(log);
            }))
        .catch(error => console.error(error.stack));
}
export {
    read as readJson, getMapImage, searchPlace, radeShapefile
}