<template>
    <div class="page-bottom">
        <span>{{ curMousesPostion }}</span>
    </div>
</template>
<script>
import { MousePosition } from "@/assets/js/map/mousePosition.js";
export default {
    name: "ShowBottomInfo",
    props: {},
    data() {
        return {
            curMousesPostion: ""
        };
    },
    computed: {
        viewer() {
            if (this.$store.state.viewer) {
                return this.$store.state.viewer;
            }
            return null;
        }
    },
    mounted() {
        this.$nextTick(() => {
            const viewer = this.viewer;
            if (viewer) {
                this.getPosition(viewer);
            }
        });
    },
    methods: {
        getPosition(viewer) {
            let mousePosition = new MousePosition(viewer, {
                callback: res => {
                    this.curMousesPostion = res;
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.page-bottom {
    position: fixed;
    bottom: 8px;
    right: 10px;
    height: 1.2em;
    background: rgba(50, 50, 50, 0);
    z-index: 2;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    span {
        color: #ffffff;
        font-size: 0.6em;
    }
}
</style>
