<template>
    <div>
        <div class="pop-up">
            <div class="title">基础地图</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in baseLayer"
                    :key="index"
                    class="box"
                    @click="changeBaseLayer(item)"
                    :class="{ 'active-box': item.active }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                    <div
                        class="name-flag"
                        :class="{ 'name-flag-active': item.active }"
                    >
                        <span>{{ item.title }}</span>
                    </div>
                </div>
                <div class="box" @click="addLayer(0)">
                    <div class="img-flag" title="添加地图">
                        <img :src="'./images/public/add.png'" />
                    </div>
                    <div class="name-flag">
                        <span>添加地图</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="split-item-line"></div>
        <div class="pop-up">
            <div class="title">叠加地图</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in overlayLayer"
                    :key="index"
                    class="box"
                    @click="changeOverlayLayer(item)"
                    :class="{ 'active-box': item.active }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                    <div class="name-flag">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
                <div class="box" @click="addLayer(1)">
                    <div class="img-flag" title="添加地图">
                        <img :src="'./images/public/add.png'" />
                    </div>
                    <div class="name-flag">
                        <span>添加地图</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="split-item-line"></div>
        <div class="pop-up">
            <div class="title">地形</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in terrainLayer"
                    :key="index"
                    class="box"
                    @click="changeTerrainlayer(item)"
                    :class="{ 'active-box': item.active }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                    <div class="name-flag">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            title="添加图层"
            v-model="isShowDlg"
            :mask-closable="false"
            @on-ok="ok"
        >
            <p>
                图层名称：<Input
                    v-model="layerName"
                    placeholder="请输入图层名称"
                    style="width: 400px"
                />
            </p>
            <p>
                图层网址：<Input
                    v-model="layerUrl"
                    placeholder="例如：https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    style="width: 400px"
                />
            </p>
            <p>
                服务器号：<Input
                    v-model="subdomains"
                    placeholder="例如：abc"
                    style="width: 400px"
                />
            </p>
            <p>
                投影坐标：
                <RadioGroup v-model="tilingScheme">
                    <Radio label="WebMercator"></Radio>
                    <Radio label="Geographic"></Radio>
                </RadioGroup>
            </p>
        </Modal>
    </div>
</template>


<script>
import { readJson, getMapImage } from "@/assets/js/readJson.js";

import {
    UrlTemplateImageryProvider,
    WebMercatorTilingScheme,
    GeographicTilingScheme,
    EllipsoidTerrainProvider,
    createWorldTerrain
} from "cesium/Cesium";

export default {
    data() {
        return {
            viewer: null,
            baseLayer: [],
            overlayLayer: [],
            terrainLayer: [],
            currentLayer: null,
            currentOverlayLayerList: [],
            layerIds: null,
            isShowDlg: false,
            layerName: "",
            subdomains: "",
            layerUrl: "",
            tilingScheme: "WebMercator",
            type: 0
        };
    },
    mounted() {
        this.initBaseLayer();
    },
    methods: {
        initBaseLayer() {
            this.viewer = this.$store.state.viewer;
            this.getData();
        },
        getData() {
            this.layerIds = this.$store.state.layerIds;
            readJson("baseLayer", this.layerIds.base, (err, res) => {
                if (err) {
                    console.log(err);
                } else {
                    for (let item of res) {
                        if (item.img == "")
                            item.img = getMapImage(item.url, item.subdomains);
                        if (item.active)
                            this.currentLayer = this.getLayer(item);
                    }
                    this.baseLayer = res;
                }
            });
            readJson("overlayLayer", this.layerIds.overlay, (err, res) => {
                if (err) {
                    console.log(err);
                } else {
                    for (let item of res) {
                        if (item.img == "")
                            item.img = getMapImage(item.url, item.subdomains);
                        if (item.active)
                            this.currentOverlayLayerList.push({
                                id: item.id,
                                layer: this.getLayer(item)
                            });
                    }
                    this.overlayLayer = res;
                }
            });
            readJson("terrainLayer", this.layerIds.terrain, (err, res) => {
                if (err) {
                    console.log(err);
                } else {
                    for (let item of res) {
                        if (item.active)
                            this.viewer.terrainProvider = this.getTerrainLayer(
                                item
                            );
                    }
                    this.terrainLayer = res;
                }
            });
        },
        getLayer(item) {
            return new UrlTemplateImageryProvider({
                url: item.url,
                subdomains: item.subdomains == "" ? "abc" : item.subdomains,
                tilingScheme:
                    item.tilingScheme == 0
                        ? new WebMercatorTilingScheme()
                        : new GeographicTilingScheme(),
                maximumLevel: item.maximumLevel
            });
        },
        changeLayer() {
            this.viewer.imageryLayers.removeAll();
            this.viewer.imageryLayers.addImageryProvider(this.currentLayer);
            this.currentOverlayLayerList.forEach(item => {
                this.viewer.imageryLayers.addImageryProvider(item.layer);
            });
        },
        changeBaseLayer(item) {
            if (item.active) return;
            for (let it of this.baseLayer) {
                it.active = false;
            }
            item.active = true;
            this.layerIds.base = [item.id];
            this.currentLayer = this.getLayer(item);
            this.changeLayer();
        },
        changeOverlayLayer(item) {
            let active = item.active;
            if (active) {
                for (let i in this.currentOverlayLayerList) {
                    let it = this.currentOverlayLayerList[i];
                    if (it.id == item.id) {
                        this.currentOverlayLayerList.splice(i, 1);
                        break;
                    }
                }
            } else {
                this.currentOverlayLayerList.push({
                    id: item.id,
                    layer: this.getLayer(item)
                });
            }
            this.changeLayer();
            item.active = !active;

            this.layerIds.overlay = [];
            for (let it of this.currentOverlayLayerList) {
                this.layerIds.overlay.push(it.id);
            }
        },
        getTerrainLayer(item) {
            if (item.url == "") {
                return new EllipsoidTerrainProvider({});
            }
            if (item.url == "cesium") {
                return createWorldTerrain({
                    requestWaterMask: true, // required for water effects
                    requestVertexNormals: true // required for terrain lighting
                });
            }
        },
        changeTerrainlayer(item) {
            if (item.active) return;
            for (let it of this.terrainLayer) {
                it.active = false;
            }
            item.active = true;
            this.layerIds.terrain = [item.id];
            this.viewer.terrainProvider = this.getTerrainLayer(item);
        },
        addLayer(type) {
            this.isShowDlg = true;
            this.type = type;
        },
        ok() {
            let layer = {
                id: this.baseLayer[this.baseLayer.length - 1].id++,
                title: this.layerName,
                img: getMapImage(this.layerUrl, this.subdomains),
                url: this.layerUrl,
                subdomains: this.subdomains == "" ? "abc" : this.subdomains,
                tilingScheme: this.tilingScheme == "WebMercator" ? 0 : 1,
                active: false
            };
            if (this.type == 0) {
                this.baseLayer.push(layer);
                this.changeBaseLayer(layer);
            } else {
                this.overlayLayer.push(layer);
                this.changeOverlayLayer(layer);
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../assets/css/leftNavigation.less";
.box {
    width: 29%;
    height: 100px;
    .img-flag {
        width: 70px;
        height: 70px;
        text-align: center;
        img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
        }
    }
    .name-flag {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .name-flag-active {
        color: #bdecf8;
    }
}
p {
    margin-bottom: 10px;
}
</style>
