/*
画图用函数接口
*/
const FITTING_COUNT = 100;
const PRE_DOUBLE = 0.0000001;

function LatLng(lat, lng) {
	return {
		lat,
		lng
	}
}

function toLatLng(pt) {
	return pt;
}

function removeRePointvar(points) //sxsim add fun
{
	let ps = [];
	ps.push(points[0]);
	for (let i = 1; i < points.length; i++) {
		if ((Math.abs(points[i - 1].lng - points[i].lng) < PRE_DOUBLE) && (Math.abs(points[i - 1].lat - points[i].lat) <
				PRE_DOUBLE)) {
			continue;
		}
		let pt = LatLng(points[i].lat, points[i].lng);
		ps.push(pt);
	}
	return ps;
}

//sxsim add 以下双线
function angle_x(point1, point2) {
	let a,
		b = Math.asin(Math.abs(point2.lat - point1.lat) / two_points_distance(point1, point2));

	if (point2.lat >= point1.lat && point2.lng >= point1.lng) {
		a = b + Math.PI;
	} else {
		if (point2.lat >= point1.lat && point2.lng < point1.lng) {
			a = Math.PI * 2.0 - b;
		} else {
			if (point2.lat < point1.lat && point2.lng < point1.lng) {
				a = b;
			} else {
				if (point2.lat < point1.lat && point2.lng >= point1.lng) {
					a = Math.PI - b;
				}
			}
		}
	}
	return a;
}

function pointOffsetBaseAngel(point1, point2, angleFlag, ratio, bFlag) {
	let angleX = angle_x(point1, point2);
	let f = bFlag ? angleX + angleFlag : angleX - angleFlag;
	let dx = ratio * Math.cos(f);
	let dy = ratio * Math.sin(f);
	let pt = LatLng(point2.lat + dy, point2.lng + dx);
	return pt;
}

function twoLineAngle(pt1, pt, pt2) {
	let angle = angle_x(pt, pt1) - angle_x(pt, pt2);
	return (angle < 0 ? angle + (Math.PI * 2.0) : angle);
}

function creatOffsetLine(pointsAll, lenth, flag) { //flag-true表示左，false表示右
	let points = removeRePointvar(pointsAll); //
	if (points.length < 2) return points;

	let ptsret = new Array();

	if (points.length == 2) {
		let tp0 = pointOffsetBaseAngel(points[1], points[0], -Math.PI / 2, lenth, flag);
		ptsret.push(tp0);
	}

	for (let i = 1; i < points.length - 1; i++) {
		let angle = twoLineAngle(points[i - 1], points[i], points[i + 1]) / 2.0;

		if (i == 1) {
			let tp0 = pointOffsetBaseAngel(points[1], points[0], -Math.PI / 2, lenth, flag);
			ptsret.push(tp0);
		}

		if (flag) {
			angle = Math.PI - angle;
		}
		let pt = pointOffsetBaseAngel(points[i - 1], points[i], angle, lenth / Math.sin(Math.PI - angle), flag);
		ptsret.push(pt);
	}

	let tpl = pointOffsetBaseAngel(points[points.length - 2], points[points.length - 1], Math.PI / 2, lenth, flag);
	ptsret.push(tpl);
	//ptsret.push(points[points.length-1]);//添加最后一个点
	return ptsret;
}
//sxsim add 以上双线
//sxsim add 闭合曲线
function latlng2WebMercator(latlng) {
	let lng = latlng.lng * 20037508.34 / 180;
	let lat = Math.log(Math.tan((90 + latlng.lat) * Math.PI / 360)) / (Math.PI / 180);
	lat = lat * 20037508.34 / 180;
	let mercator = LatLng(lat, lng);
	return mercator;
}

function WebMercator2latlon(mercator) {
	let lng = mercator.lng / 20037508.34 * 180;
	let lat = mercator.lat / 20037508.34 * 180;
	lat = 180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180)) - Math.PI / 2);
	let latlng = LatLng(lat, lng);
	return latlng;
}

function latlngs2WebMercators(latlngs) {
	let mercators = new Array();
	for (let i = 0; i < latlngs.length; i++) {
		mercators.push(latlng2WebMercator(latlngs[i]));
	}
	return mercators;
}

function WebMercators2latlons(mercators) {
	let latlngs = new Array();
	for (let i = 0; i < mercators.length; i++) {
		latlngs.push(WebMercator2latlon(mercators[i]));
	}
	return latlngs;
}

function getNewLatLngs(latlngs) {
	let latlngsTemp = new Array();
	for (let i = 0; i < latlngs.length; i++) {
		latlngsTemp.push(LatLng(latlngs[i].lat, latlngs[i].lng));
	}
	return latlngsTemp;
}

function generateClosedCurve(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);
	let psTemp = getNewLatLngs(points);
	psTemp.push(psTemp[0]);
	psTemp.push(psTemp[1]);

	let psTemp2 = [];
	for (let i = 0; i < psTemp.length - 2; i++) {
		let t = 0.3;
		let pt1 = toLatLng(psTemp[i]);
		let pt2 = toLatLng(psTemp[i + 1]);
		let pt3 = toLatLng(psTemp[i + 2]);
		let psTemp3 = fn(t, pt1, pt2, pt3);
		psTemp2 = psTemp2.concat(psTemp3);
	}

	let pt = toLatLng(psTemp2[psTemp2.length - 1]);
	psTemp2.pop();
	psTemp2.splice(0, 0, pt);

	let psRet = new Array();
	for (let i = 0; i < psTemp.length - 2; i++) {
		let pt1 = toLatLng(psTemp[i]);
		let pt2 = toLatLng(psTemp[i + 1]);

		psRet.push(pt1);
		for (let t = 0; t <= FITTING_COUNT / 2; t++) {
			let pnt = hornMouthPointInRect(t / (FITTING_COUNT / 2), pt1, psTemp2[i * 2], psTemp2[i * 2 + 1], pt2);
			psRet.push(pnt);
		}
	}
	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function arc(point, radius, startAngle, endAngle) {
	let lng, lat;
	let points = [];
	let angelDelta = endAngle - startAngle;
	angelDelta = angelDelta < 0 ? angelDelta + (Math.PI * 2.0) : angelDelta;
	for (let i = 0; i <= FITTING_COUNT; i++) {
		let angel = startAngle + angelDelta * i / FITTING_COUNT;
		lng = point.lng + radius * Math.cos(angel);
		lat = point.lat + radius * Math.sin(angel);
		points.push(LatLng(lat, lng));
	}
	return points;
}

function triangle_circle_center(p1, p2, p3) {
	let point1 = LatLng((p1.lat + p2.lat) / 2, (p1.lng + p2.lng) / 2);
	let point2 = LatLng(point1.lat + p1.lng - p2.lng, point1.lng - p1.lat + p2.lat);
	let point3 = LatLng((p1.lat + p3.lat) / 2, (p1.lng + p3.lng) / 2);
	let point4 = LatLng(point3.lat + p1.lng - p3.lng, point3.lng - p1.lat + p3.lat);
	return crossPoint(point1, point2, point3, point4);
}

function crossPoint(point1, point2, point3, point4) {
	if (point1.lat == point2.lat) {
		let f = (point4.lng - point3.lng) / (point4.lat - point3.lat);
		let lng = f * (point1.lat - point3.lat) + point3.lng;
		let lat = point1.lat;
		return LatLng(lat, lng);
	}
	if (point3.lat == point4.lat) {
		let e = (point2.lng - point1.lng) / (point2.lat - point1.lat);
		let lng = e * (point3.lat - point1.lat) + point1.lng;
		let lat = point3.lat;
		return LatLng(lat, lng);
	}
	let e = (point2.lng - point1.lng) / (point2.lat - point1.lat);
	let f = (point4.lng - point3.lng) / (point4.lat - point3.lat);
	let lat = (e * point1.lat - point1.lng - f * point3.lat + point3.lng) / (e - f);
	let lng = e * lat - e * point1.lat + point1.lng;
	return LatLng(lat, lng);
}

function generateArc(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);
	
	let pt1 = points[0];
	let pt2 = points[1];
	let pt3 = points[2];

	let ptCenter = triangle_circle_center(pt1, pt2, pt3);
	let radius = two_points_distance(pt1, ptCenter);
	let startAngleTemp = angle_x(pt1, ptCenter);
	let endAngleTemp = angle_x(pt2, ptCenter);

	let startAngle = startAngleTemp;
	let endAngle = endAngleTemp;
	if (twoLineRateCompare(pt1, pt2, pt3)) {
		startAngle = endAngleTemp;
		endAngle = startAngleTemp;
	}
	let psRet = removeRePointvar(arc(ptCenter, radius, startAngle, endAngle));
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function caculateCirclePoints(center, radius, isHorizontal) {
	let lng, lat, angle;
	let psRet = [];
	for (let i = 0; i <= FITTING_COUNT; i++) {
		angle = Math.PI * 2 * i / FITTING_COUNT;
		if (isHorizontal) {
			lng = center.lng + radius * Math.cos(angle);
			lat = center.lat + radius * Math.sin(angle);
		} else {
			lng = center.lng + radius * Math.sin(angle);
			lat = center.lat + radius * Math.cos(angle);
		}
		psRet.push(LatLng(lat, lng));
	}
	return psRet;
}

function caculateEllipsePoints(center, a, b, angle) {
	let psRet = [];
	for (let i = 0; i <= FITTING_COUNT; i++) {
		let rotation_angle = Math.PI * 2 * i / FITTING_COUNT;
		let lng = center.lng + a * Math.cos(rotation_angle);
		let lat = center.lat + b * Math.sin(rotation_angle);

		let pt = LatLng(lat, lng);
		pt = rotatePoint(angle, pt, center);

		psRet.push(pt);
	}
	return psRet;
}

function DegreesToRadians(angle) {
	return Math.PI / 180 * angle;
}

function RadiansToDegrees(angle) {
	return 180 / Math.PI * angle;
}

function rotatePoint(angle, p, origin) {
	let pRet = toLatLng(p);
	let dis = two_points_distance(p, origin);
	if (dis < PRE_DOUBLE) return pRet;
	let b = angle_x(p, origin);

	pRet.lng = origin.lng + dis * Math.cos(DegreesToRadians(angle) + b);
	pRet.lat = origin.lat + dis * Math.sin(DegreesToRadians(angle) + b);
	return pRet;
}

function getVerticalPoint(pt1, pt2, pt3) {
	let ptRet = pt3;
	if (Math.abs(pt2.lng - pt1.lng) < PRE_DOUBLE && Math.abs(pt2.lat - pt1.lat) < PRE_DOUBLE) {
		ptRet.lat = pt2.lat;
		ptRet.lng = pt2.lng;
		return ptRet;
	} else if (Math.abs(pt2.lng - pt1.lng) < PRE_DOUBLE) {
		ptRet.lat = pt2.lat;
		return ptRet;
	} else if (Math.abs(pt2.lat - pt1.lat) < PRE_DOUBLE) {
		ptRet.lng = pt2.lng;
		return ptRet;
	}
	let ptTemp = {};
	let k = (pt2.lat - pt1.lat) / (pt2.lng - pt1.lng);
	let b = pt2.lat - k * pt2.lng;

	let k1 = -1.0 / k;
	let b1 = pt3.lat - k1 * pt3.lng;
	ptTemp.lng = (b1 - b) / (k - k1);
	ptTemp.lat = k * ptTemp.lng + b;

	let ptCenter = center_point_of_twopoints(pt3, pt2);
	ptRet.lng = 2.0 * ptCenter.lng - ptTemp.lng;
	ptRet.lat = 2.0 * ptCenter.lat - ptTemp.lat;
	return ptRet;
}

function generateRectangle(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);

	let pt1 = points[0];
	let pt2 = points[1];

	let psRet = [];
	let pt3 = getVerticalPoint(points[0], points[1], points[2]);
	let pCenter = center_point_of_twopoints(pt1, pt3);
	let pt4 = LatLng(pCenter.lat * 2.0 - pt2.lat, pCenter.lng * 2.0 - pt2.lng);
	psRet.push(pt1);
	psRet.push(pt2);
	psRet.push(pt3);
	psRet.push(pt4);
	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateCircle(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);

	let center = points[0];
	let pt = points[1];
	let radius = two_points_distance(center, pt);
	let psRet = caculateCirclePoints(center, radius, true);
	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateEllipse(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);

	let center = points[0];
	if (points.length == 2) {
		let pt = points[1];
		let radius = two_points_distance(center, pt);
		let psRet = caculateCirclePoints(center, radius, true);
		psRet = removeRePointvar(psRet);
		psRet = WebMercators2latlons(psRet);
		return psRet;
	}
	let pt1 = toLatLng(points[1]);
	let pt2 = toLatLng(points[2]);

	let a = two_points_distance(center, pt1);
	let b = two_points_distance(center, pt2);

	let angle = twoLineAngle(pt1, center, LatLng(pt1.lng, center.lat));

	let psRet = caculateEllipsePoints(center, a, b, RadiansToDegrees(angle));
	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateLune(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);

	let pts = getNewLatLngs(points);
	if (points.length == 2) {
		let mid = center_point_of_twopoints(pts[0], pts[1]);
		let d = two_points_distance(pts[0], mid);
		let pnt = pointOffsetBaseAngel(pts[0], mid, Math.PI / 2, d);
		pts.push(pnt);
	}
	let pt1 = toLatLng(pts[0]);
	let pt2 = toLatLng(pts[1]);
	let pt3 = toLatLng(pts[2]);

	let ptCenter = triangle_circle_center(pt1, pt2, pt3);
	let radius = two_points_distance(pt1, ptCenter);
	let startAngleTemp = angle_x(pt1, ptCenter);
	let endAngleTemp = angle_x(pt2, ptCenter);
	let startAngle = startAngleTemp;
	let endAngle = endAngleTemp;
	if (twoLineRateCompare(pt1, pt2, pt3)) {
		startAngle = endAngleTemp;
		endAngle = startAngleTemp;
	}
	let ptsRet = arc(ptCenter, radius, startAngle, endAngle);
	ptsRet = removeRePointvar(ptsRet);
	ptsRet = WebMercators2latlons(ptsRet);
	return ptsRet;
}

function generateSector(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);

	let pt1 = toLatLng(points[0]);
	let pt2 = toLatLng(points[1]);
	let pt3 = toLatLng(points[2]);
	let radius = two_points_distance(pt2, pt1);
	let startAngle = angle_x(pt2, pt1);
	let endAngle = angle_x(pt3, pt1);
	let ptsRet = arc(pt1, radius, startAngle, endAngle);
	ptsRet.push(pt1);
	ptsRet = removeRePointvar(ptsRet);
	ptsRet = WebMercators2latlons(ptsRet);
	return ptsRet;
}
//sxsim add below 曲线
function generateCurve(points) {
	if (points.length < 3) return points;
	let t = 0.3;
	return removeRePointvar(curveLine(t, points));
}

function curveLine(t, latlngs) {
	let pt = fq(t, latlngs);
	let ps = new Array();
	let pt1;
	let pt2;
	let pt3;
	ps.push(pt);
	for (let i = 0; i < latlngs.length - 2; i++) {
		pt1 = LatLng(latlngs[i].lat, latlngs[i].lng);
		pt2 = LatLng(latlngs[i + 1].lat, latlngs[i + 1].lng);
		pt3 = LatLng(latlngs[i + 2].lat, latlngs[i + 2].lng);
		let psTemp = fn(t, pt1, pt2, pt3);
		ps = ps.concat(psTemp);
	}
	let pt4 = fr(t, latlngs);
	ps.push(pt4);
	let psRet = new Array();
	for (let i = 0; i < latlngs.length - 1; i++) {
		pt1 = LatLng(latlngs[i].lat, latlngs[i].lng);
		pt2 = LatLng(latlngs[i + 1].lat, latlngs[i + 1].lng);
		psRet.push(pt1);
		for (let n = 0; n < FITTING_COUNT / 2; n++) {
			let pnt = hornMouthPointInRect(n / (FITTING_COUNT / 2), pt1, ps[i * 2], ps[i * 2 + 1], pt2);
			psRet.push(pnt);
		}
		psRet.push(pt2);
	}
	return psRet;
}

function fq(t, latlngs) {
	let pt1 = LatLng(latlngs[0].lat, latlngs[0].lng);
	let pt2 = LatLng(latlngs[1].lat, latlngs[1].lng);
	let pt3 = LatLng(latlngs[2].lat, latlngs[2].lng);

	let psTemp = fn(0, pt1, pt2, pt3);
	let pt4 = psTemp[0];
	let pt5 = fo(pt1, pt2, pt3);
	let len = Math.sqrt(pt5.lng * pt5.lng + pt5.lat * pt5.lat);

	let x;
	let y;
	if (len > 0.0001) {
		let mid = center_point_of_twopoints(pt1, pt2);
		let pX = pt1.lng - mid.lng;
		let pY = pt1.lat - mid.lat;
		let d1 = two_points_distance(pt1, pt2);
		let n = 2.0 / d1;
		let nX = -n * pY;
		let nY = n * pX;
		let a11 = nX * nX - nY * nY;
		let a12 = 2 * nX * nY;
		let a22 = nY * nY - nX * nX;
		let dX = pt4.lng - mid.lng;
		let dY = pt4.lat - mid.lat;
		x = mid.lng + a11 * dX + a12 * dY;
		y = mid.lat + a12 * dX + a22 * dY;
	} else {
		x = pt1.lng + t * (pt2.lng - pt1.lng);
		y = pt1.lat + t * (pt2.lat - pt1.lat);
	}
	while (x > 180) x -= 360.0;
	while (x < -180) x += 360.0;

	while (y > 90) x -= 180.0;
	while (y < -90) x += 180.0;

	let pt = LatLng(y, x);
	return pt;
}

function fr(t, ps) {
	let num = ps.length;
	let ps1 = ps[num - 1];
	let ps2 = ps[num - 2];
	let ps3 = ps[num - 3];
	let pt1 = LatLng(ps1.lat, ps1.lng);
	let pt2 = LatLng(ps2.lat, ps2.lng);
	let pt3 = LatLng(ps3.lat, ps3.lng);

	let psTemp = fn(0, pt3, pt2, pt1);
	let pt = psTemp[1];
	let ptTemp = fo(pt3, pt2, pt1);
	let len = Math.sqrt(ptTemp.lng * ptTemp.lng + ptTemp.lat * ptTemp.lat);
	let x;
	let y;
	if (len > 0.0001) {
		let mid = center_point_of_twopoints(pt2, pt1);
		let pX = pt1.lng - mid.lng;
		let pY = pt1.lat - mid.lat;
		let d1 = two_points_distance(pt2, pt1);
		let n = 2 / d1;
		let nX = -n * pY;
		let nY = n * pX;
		let a11 = nX * nX - nY * nY;
		let a12 = 2 * nX * nY;
		let a22 = nY * nY - nX * nX;
		let dX = pt.lng - mid.lng;
		let dY = pt.lat - mid.lat;
		x = mid.lng + a11 * dX + a12 * dY;
		y = mid.lat + a12 * dX + a22 * dY;
	} else {
		x = pt1.lng + t * (pt2.lng - pt1.lng);
		y = pt1.lat + t * (pt2.lat - pt1.lat);
	}

	let ptRet = LatLng(y, x);
	return ptRet;
}

function fn(t, pt1, pt2, pt3) {
	let a = fo(pt1, pt2, pt3);
	let b = Math.sqrt(a.lng * a.lng + a.lat * a.lat);
	let uX = a.lng / b;
	let uY = a.lat / b;
	let d1 = two_points_distance(pt1, pt2);
	let d2 = two_points_distance(pt2, pt3);

	let dt;
	let x;
	let y;

	let c = LatLng(0.0, 0.0);
	let d = LatLng(0.0, 0.0);

	let points = new Array();
	if (b > 0.0001) {
		if (twoLineRateCompare(pt1, pt2, pt3)) {
			dt = t * d1;
			x = pt2.lng - dt * uY;
			y = pt2.lat + dt * uX;
			c.lng = x;
			c.lat = y;
			dt = t * d2;
			x = pt2.lng + dt * uY;
			y = pt2.lat - dt * uX;
			d.lng = x;
			d.lat = y;
		} else {
			dt = t * d1;
			x = pt2.lng + dt * uY;
			y = pt2.lat - dt * uX;
			c.lng = x;
			c.lat = y;
			dt = t * d2;
			x = pt2.lng - dt * uY;
			y = pt2.lat + dt * uX;
			d.lng = x;
			d.lat = y;
		}
	} else {
		x = pt2.lng + t * (pt1.lng - pt2.lng);
		y = pt2.lat + t * (pt1.lat - pt2.lat);
		c.lng = x;
		c.lat = y;
		x = pt2.lng + t * (pt3.lng - pt2.lng);
		y = pt2.lat + t * (pt3.lat - pt2.lat);
		d.lng = x;
		d.lat = y;
	}
	let pts1 = LatLng(c.lat, c.lng);
	let pts2 = LatLng(d.lat, d.lng);

	points.push(pts1);
	points.push(pts2);
	return points;
}

function fo(pt1, pt2, pt3) {
	let dX1 = pt1.lng - pt2.lng;
	let dY1 = pt1.lat - pt2.lat;

	let d1 = Math.sqrt(dX1 * dX1 + dY1 * dY1);
	dX1 /= d1;
	dY1 /= d1;
	let dX2 = pt3.lng - pt2.lng;
	let dY2 = pt3.lat - pt2.lat;
	let d2 = Math.sqrt(dX2 * dX2 + dY2 * dY2);
	dX2 /= d2;
	dY2 /= d2;
	let uX = dX1 + dX2;
	let uY = dY1 + dY2;

	let point = LatLng(uY, uX);
	return point;
}

function hornMouthPointInRect(t, point1, point2, point3, point4) {
	t = t < 1 ? t : 1.0;
	t = t > 0 ? t : 0.0;

	let tp = 1.0 - t;
	let t2 = t * t;
	let t3 = t2 * t;
	let tp2 = tp * tp;
	let tp3 = tp2 * tp;
	let x = (tp3 * point1.lng) + (3 * tp2 * t * point2.lng) + (3 * tp * t2 * point3.lng) + (t3 * point4.lng);
	let y = (tp3 * point1.lat) + (3 * tp2 * t * point2.lat) + (3 * tp * t2 * point3.lat) + (t3 * point4.lat);

	let point = LatLng(y, x);
	return point;
}

function two_points_distance(point1, point2) {
	return Math.sqrt(Math.pow((point1.lng - point2.lng), 2.0) + Math.pow((point1.lat - point2.lat), 2.0));
}

function twoLineRateCompare(point1, point2, point3) {
	return ((point3.lat - point1.lat) * (point2.lng - point1.lng) > (point2.lat - point1.lat) * (point3.lng - point1.lng));
}

function center_point_of_twopoints(pointa, pointb) {
	let point = LatLng((pointa.lat + pointb.lat) / 2, (pointa.lng + pointb.lng) / 2);
	return point;
}
//sxsim add above 曲线

//sxsim add Gather集结地
function generateGather(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);

	let pts = getNewLatLngs(points);
	if (points.length == 2) {
		let mid = center_point_of_twopoints(pts[0], pts[1]);
		let d = two_points_distance(pts[0], mid) / 0.9;
		let pt = pointOffsetBaseAngel(pts[0], mid, Math.PI / 2, d, true);
		let ptsTemp = [];
		ptsTemp.push(pts[0]);
		ptsTemp.push(pt);
		ptsTemp.push(pts[1]);
		pts = ptsTemp;
	}
	let mid = center_point_of_twopoints(pts[0], pts[2]);
	pts.push(mid);
	pts.push(pts[0]);
	pts.push(pts[1]);

	let pts1 = [];
	for (let i = 0; i < pts.length - 2; i++) {
		let t = 0.4;
		let pt1 = toLatLng(pts[i]);
		let pt2 = toLatLng(pts[i + 1]);
		let pt3 = toLatLng(pts[i + 2]);
		let ptsTemp = fn(t, pt1, pt2, pt3);
		pts1 = pts1.concat(ptsTemp);
	}

	let pt = pts1[pts1.length - 1];
	pts1.pop();
	pts1.splice(0, 0, pt);

	let psRet = [];
	for (let i = 0; i < pts.length - 2; i++) {
		let pt1 = toLatLng(pts[i]);
		let pt2 = toLatLng(pts[i + 1]);
		psRet.push(pt1);
		for (let t = 0; t <= FITTING_COUNT / 2; t++) {
			let pt = hornMouthPointInRect(t / (FITTING_COUNT / 2), pt1, pts1[i * 2], pts1[i * 2 + 1], pt2);
			psRet.push(pt);
		}
	}
	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateArrowStraight(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);
	let maxArrowLength = 3000000.0;
	let arrowLengthScale = 5.0;

	let pt1 = toLatLng(points[0]);
	let pt2 = toLatLng(points[1]);
	let distance = two_points_distance(pt1, pt2);
	let len = distance / arrowLengthScale;
	len = len > maxArrowLength ? maxArrowLength : len;
	let pt3 = pointOffsetBaseAngel(pt1, pt2, Math.PI / 6, len, false);
	let pt4 = pointOffsetBaseAngel(pt1, pt2, Math.PI / 6, len, true);
	let psRet = [];
	psRet.push(pt1);
	psRet.push(pt2);
	psRet.push(pt3);
	psRet.push(pt2);
	psRet.push(pt4);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function path_distance(points) {
	let distance = 0;
	for (let i = 0; i < points.length - 1; i++) {
		distance += two_points_distance(points[i], points[i + 1]);
	}
	return distance;
}

function pow_99(points) {
	return Math.pow(path_distance(points), 0.99);
}

function generateArrawAssaultDirection(points) {
	if (points.length < 2) return points;

	let tailWidthFactor = 0.2;
	let neckWidthFactor = 0.25;
	let headWidthFactor = 0.3;
	let headAngle = Math.PI / 4;
	let neckAngle = Math.PI * 0.17741;

	points = latlngs2WebMercators(points);
	let pts = getNewLatLngs(points);
	let pt1 = pts[0];
	let pt2 = pts[1];

	let len = pow_99(pts);

	let tail = len * tailWidthFactor;
	let neck = len * neckWidthFactor;
	let head = len * headWidthFactor;
	let p1 = pointOffsetBaseAngel(pt2, pt1, Math.PI / 2, tail, true);
	let p2 = pointOffsetBaseAngel(pt2, pt1, Math.PI / 2, tail, false);
	let p3 = pointOffsetBaseAngel(pt1, pt2, headAngle, head, false);
	let p4 = pointOffsetBaseAngel(pt1, pt2, headAngle, head, true);
	let p5 = pointOffsetBaseAngel(pt1, pt2, neckAngle, neck, false);
	let p6 = pointOffsetBaseAngel(pt1, pt2, neckAngle, neck, true);

	let psRet = [];
	psRet.push(p1);
	psRet.push(p5);
	psRet.push(p3);
	psRet.push(pt2);
	psRet.push(p4);
	psRet.push(p6);
	psRet.push(p2);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function attackArrow1(points, pt1, pt2, isTailed) {
	let headHeightFactor = 0.18;
	let headWidthFactor = 0.3;
	let neckHeightFactor = 0.85;
	let neckWidthFactor = 0.15;
	let headTailFactor = 0.8;

	if (isTailed) {
		headHeightFactor = 0.18;
		headWidthFactor = 0.3;
		neckHeightFactor = 0.85;
		neckWidthFactor = 0.15;
		headTailFactor = 0.8;
	}

	let len = pow_99(points);
	let head = len * headHeightFactor;
	let p1 = points[points.length - 1];
	len = two_points_distance(p1, points[points.length - 2]);
	let dis = two_points_distance(pt1, pt2);
	if (head > dis * headTailFactor) {
		head = dis * headTailFactor;
	}
	let head1 = head * headWidthFactor;
	let head2 = head * neckWidthFactor;
	head = head > len ? len : head;
	let head3 = head * neckHeightFactor;
	let p2 = pointOffsetBaseAngel(points[points.length - 2], p1, 0, head, true);
	let p3 = pointOffsetBaseAngel(points[points.length - 2], p1, 0, head3, true);
	let p4 = pointOffsetBaseAngel(p1, p2, Math.PI / 2, head1, false);
	let p5 = pointOffsetBaseAngel(p1, p2, Math.PI / 2, head1, true);
	let p6 = pointOffsetBaseAngel(p1, p3, Math.PI / 2, head2, false);
	let p7 = pointOffsetBaseAngel(p1, p3, Math.PI / 2, head2, true);

	let psRet = [];
	psRet.push(p6);
	psRet.push(p4);
	psRet.push(p1);
	psRet.push(p5);
	psRet.push(p7);
	return psRet;
}

function attackArrow2(points, pt1, pt2, distance) {
	let dis = path_distance(points);
	let len = pow_99(points);
	let lenDis = len * distance;
	let dis1 = two_points_distance(pt1, pt2);
	let dis2 = (lenDis - dis1) / 2;
	let dis3 = 0;
	let pts1 = [],
		pts2 = [];
	for (let i = 1; i < points.length - 1; i++) {
		let angle = twoLineAngle(points[i - 1], points[i], points[i + 1]) / 2;
		dis3 += two_points_distance(points[i - 1], points[i]);
		let w = (lenDis / 2 - dis3 / dis * dis2) / Math.sin(angle);
		let p1 = pointOffsetBaseAngel(points[i - 1], points[i], Math.PI - angle, w, true);
		let p2 = pointOffsetBaseAngel(points[i - 1], points[i], angle, w, false);
		pts1.push(p1);
		pts2.push(p2);
	}
	return pts1.concat(pts2);
}

function attackArrowPart(ps) {
	if (ps.length <= 2) {
		return ps;
	}
	let n = 2;
	let psRet = [];
	let m = ps.length - n - 1;
	psRet.push(toLatLng(ps[0]));
	for (let i = 0; i <= m; i++) {
		for (let t = 0; t <= 1; t += 2.0 / FITTING_COUNT) {
			let lng = 0.0;
			let lat = 0.0;
			for (let k = 0; k <= n; k++) {
				let ratio = attackArrowRatio(k, t);
				lng += ratio * ps[i + k].lng;
				lat += ratio * ps[i + k].lat;
			}
			psRet.push(LatLng(lat, lng));
		}
	}
	psRet.push(ps[ps.length - 1]);
	return psRet;
}

function attackArrowRatio(k, t) {
	if (k == 0) {
		return Math.pow(t - 1.0, 2.0) / 2.0;
	}
	if (k == 1) {
		return (-2.0 * Math.pow(t, 2.0) + 2.0 * t + 1.0) / 2.0;
	}
	if (k == 2) {
		return Math.pow(t, 2.0) / 2.0;
	}
	return 0.0;
}

function generateArrawAttack(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);
	let pt1 = toLatLng(points[0]);
	let pt2 = toLatLng(points[1]);
	if (twoLineRateCompare(points[0], points[1], points[2])) {
		pt1 = toLatLng(points[1]);
		pt2 = toLatLng(points[0]);
	}
	let pt3 = center_point_of_twopoints(pt1, pt2);
	let ps1 = [];
	ps1.push(pt3);
	ps1 = ps1.concat(points.slice(2));
	let ps2 = attackArrow1(ps1, pt1, pt2, false);
	let pt4 = toLatLng(ps2[0]);
	let pt5 = toLatLng(ps2[4]);
	let distance = two_points_distance(pt1, pt2) / pow_99(ps1);
	let ps3 = attackArrow2(ps1, pt4, pt5, distance);
	let len = ps3.length;

	let ps4 = [];
	ps4.push(pt1);
	ps4 = ps4.concat(ps3.slice(0, len / 2));
	ps4.push(pt4);
	let ps5 = [];
	ps5.push(pt2);
	ps5 = ps5.concat(ps3.slice(len / 2, len));
	ps5.push(pt5);

	ps4 = attackArrowPart(ps4);
	ps5 = attackArrowPart(ps5);

	let psRet = [];
	psRet = psRet.concat(ps4, ps2, ps5.reverse());
	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateArrawAttackTailled(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);
	let tailWidthFactor = 0.1;
	let swallowTailFactor = 1;
	let pts = getNewLatLngs(points);
	let pt1 = toLatLng(pts[0]);
	let pt2 = toLatLng(pts[1]);
	if (twoLineRateCompare(pts[0], pts[1], pts[2])) {
		pt1 = toLatLng(pts[1]);
		pt2 = toLatLng(pts[0]);
	}
	let pt3 = center_point_of_twopoints(pt1, pt2);
	let pts1 = [];
	pts1.push(pt3);
	pts1 = pts1.concat(pts.slice(2));
	let pts2 = attackArrow1(pts1, pt1, pt2, true);
	let pt4 = toLatLng(pts2[0]);
	let pt5 = toLatLng(pts2[4]);
	let dis = two_points_distance(pt1, pt2);
	let pow99 = pow_99(pts1);
	let len = pow99 * tailWidthFactor * swallowTailFactor;
	let swallowTailpt = pointOffsetBaseAngel(pts1[1], pts1[0], 0, len, true);
	let disPow = dis / pow99;
	let pts3 = attackArrow2(pts1, pt4, pt5, disPow);
	let num = pts3.length;

	let pts4 = [];
	pts4.push(pt1);
	pts4 = pts4.concat(pts3.slice(0, num / 2));
	pts4.push(pt4);
	let pts5 = [];
	pts5.push(pt2);
	pts5 = pts5.concat(pts3.slice(num / 2, num));
	pts5.push(pt5);

	pts4 = attackArrowPart(pts4);
	pts5 = attackArrowPart(pts5);

	let psRet = [];
	psRet = psRet.concat(pts4, pts2, pts5.reverse());

	let psRet1 = [];
	psRet1.push(swallowTailpt);

	psRet = psRet.concat(psRet1);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateArrawFine(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);

	let tailWidthFactor = 0.15;
	let neckWidthFactor = 0.2;
	let headWidthFactor = 0.25;
	let headAngle = Math.PI / 8.5;
	let neckAngle = Math.PI / 13;

	let pt1 = points[0];
	let pt2 = points[1];

	let len = pow_99(points);

	let tail = len * tailWidthFactor;
	let neck = len * neckWidthFactor;
	let head = len * headWidthFactor;
	let p1 = pointOffsetBaseAngel(pt2, pt1, Math.PI / 2, tail, true);
	let p2 = pointOffsetBaseAngel(pt2, pt1, Math.PI / 2, tail, false);
	let p3 = pointOffsetBaseAngel(pt1, pt2, headAngle, head, false);
	let p4 = pointOffsetBaseAngel(pt1, pt2, headAngle, head, true);
	let p5 = pointOffsetBaseAngel(pt1, pt2, neckAngle, neck, false);
	let p6 = pointOffsetBaseAngel(pt1, pt2, neckAngle, neck, true);

	let psRet = [];
	psRet.push(p1);
	psRet.push(p5);
	psRet.push(p3);
	psRet.push(pt2);
	psRet.push(p4);
	psRet.push(p6);
	psRet.push(p2);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function squadCombat(points) {
	let tailWidthFactor = 0.1;
	let pow99 = pow_99(points);
	let tail = pow99 * tailWidthFactor;
	let pt1 = pointOffsetBaseAngel(points[1], points[0], Math.PI / 2, tail, false);
	let pt2 = pointOffsetBaseAngel(points[1], points[0], Math.PI / 2, tail, true);
	let psRet = [];
	psRet.push(pt1);
	psRet.push(pt2);
	return psRet;
}

function generateArrawSquadCambat(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);

	let tailWidthFactor = 0.1;
	let pts = getNewLatLngs(points);
	let pts1 = squadCombat(pts);
	let pts2 = attackArrow1(pts, pts1[0], pts1[1]);
	let pt1 = pts2[0];
	let pt2 = pts2[4];
	let pts3 = attackArrow2(pts, pt1, pt2, tailWidthFactor);
	let num = pts3.length;

	let pts4 = [];
	pts4.push(toLatLng(pts1[0]));
	pts4 = pts4.concat(pts3.slice(0, num / 2));
	pts4.push(pt1);
	let pts5 = [];
	pts5.push(toLatLng(pts1[1]));
	pts5 = pts5.concat(pts3.slice(num / 2, num));
	pts5.push(pt2);

	pts4 = attackArrowPart(pts4);
	pts5 = attackArrowPart(pts5);

	let psRet = [];
	psRet = psRet.concat(pts4, pts2, pts5.reverse());

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function tailedSquadCombat(points) {
	let tailWidthFactor = 0.1;
	let swallowTailFactor = 1;
	let pow99 = pow_99(points);
	let tail = pow99 * tailWidthFactor;
	let pt1 = pointOffsetBaseAngel(points[1], points[0], Math.PI / 2, tail, false);
	let pt2 = pointOffsetBaseAngel(points[1], points[0], Math.PI / 2, tail, true);
	let len = tail * swallowTailFactor;
	let pt3 = pointOffsetBaseAngel(points[1], points[0], 0, len, true);
	let psRet = [];
	psRet.push(pt1);
	psRet.push(pt3);
	psRet.push(pt2);
	return psRet;
}

function generateArrawTailedSquadCambat(points) {
	if (points.length < 2) return points;
	points = latlngs2WebMercators(points);
	let tailWidthFactor = 0.1;
	let pts = getNewLatLngs(points);
	let pts1 = tailedSquadCombat(pts);
	let pts2 = attackArrow1(pts, pts1[0], pts1[2]);
	let pt1 = toLatLng(pts2[0]);
	let pt2 = toLatLng(pts2[4]);
	let pts3 = attackArrow2(pts, pt1, pt2, tailWidthFactor);
	let num = pts3.length;

	let pts4 = [];
	pts4.push(toLatLng(pts1[0]));
	pts4 = pts4.concat(pts3.slice(0, num / 2));
	pts4.push(pt1);
	let pts5 = [];
	pts5.push(toLatLng(pts1[2]));
	pts5 = pts5.concat(pts3.slice(num / 2, num));
	pts5.push(pt2);

	pts4 = attackArrowPart(pts4);
	pts5 = attackArrowPart(pts5);

	let psRet = [];
	psRet = psRet.concat(pts4, pts2, pts5.reverse());
	psRet.push(pts1[1]);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function doubleArrowCenter(p_1, p_2, p_3) {
	let pt2 = p_3;
	let tailWidthFactor = 0.2;
	let neckWidthFactor = 0.25;
	let headWidthFactor = 0.3;
	let headAngle = 0.3;
	let neckAngle = Math.PI / 16;

	let pt1 = center_point_of_twopoints(p_1, p_2);
	let len = two_points_distance(pt1, pt2);

	let tail = len * tailWidthFactor;
	let neck = len * neckWidthFactor;
	let head = len * headWidthFactor;
	let p1 = pointOffsetBaseAngel(pt2, pt1, Math.PI / 2, tail, true);
	let p2 = pointOffsetBaseAngel(pt2, pt1, Math.PI / 2, tail, false);
	let p3 = pointOffsetBaseAngel(pt1, pt2, headAngle, head, false);
	let p4 = pointOffsetBaseAngel(pt1, pt2, headAngle, head, true);
	let p5 = pointOffsetBaseAngel(pt1, pt2, neckAngle, neck, false);
	let p6 = pointOffsetBaseAngel(pt1, pt2, neckAngle, neck, true);

	let p_center1 = center_point_of_twopoints(p1, p5);
	let p_center2 = center_point_of_twopoints(p6, p2);


	let p_center_low1 = center_point_of_twopoints(p1, p_center1);
	let p_center_low2 = center_point_of_twopoints(p2, p_center2);

	let p_center_high1 = center_point_of_twopoints(p5, p_center1);
	let p_center_high2 = center_point_of_twopoints(p6, p_center2);


	let psRet = [];
	psRet.push(p2);
	psRet.push(p_center_low2);
	psRet.push(p_center_high2);
	psRet.push(p6);
	psRet.push(p4);
	psRet.push(pt2);
	psRet.push(p3);
	psRet.push(p5);
	psRet.push(p_center_high1);
	psRet.push(p_center_low1);
	psRet.push(p1);
	return psRet;
}

function doubleArrow1(pt1, pt2, pt3, bFlag) {
	let p1 = center_point_of_twopoints(pt1, pt2);
	let len = two_points_distance(p1, pt3);
	let p2 = pointOffsetBaseAngel(pt3, p1, 0, len * 0.3, true);
	let p3 = pointOffsetBaseAngel(pt3, p1, 0, len * 0.5, true);
	p2 = pointOffsetBaseAngel(p1, p2, Math.PI / 2, len / 5, bFlag);
	p3 = pointOffsetBaseAngel(p1, p3, Math.PI / 2, len / 4, bFlag);
	let ps1 = [];
	ps1.push(p1);
	ps1.push(p2);
	ps1.push(p3);
	ps1.push(pt3);
	let ps2 = doubleArrow2(ps1);
	let p4 = ps2[0];
	let p5 = ps2[4];
	let disPow = two_points_distance(pt1, pt2) / pow_99(ps1) / 2;
	let ps3 = doubleArrow3(ps1, p4, p5, disPow);
	let n = ps3.length;
	let ps4 = ps3.slice(0, n / 2);
	let ps5 = ps3.slice(n / 2, n);
	ps4.push(p4);
	ps5.push(p5);
	ps4 = ps4.reverse();
	ps4.push(pt2);
	ps5 = ps5.reverse();
	ps5.push(pt1);

	return ps4.reverse().concat(ps2, ps5);
}

function doubleArrow2(points) {
	let headHeightFactor = 0.25;
	let headWidthFactor = 0.3;
	let neckHeightFactor = 0.85;
	let neckWidthFactor = 0.15;

	let len = pow_99(points);
	let head = len * headHeightFactor;
	let p1 = points[points.length - 1];
	let headWidth = head * headWidthFactor;
	let neck = head * neckWidthFactor;
	let neckHeight = head * neckHeightFactor;
	let p2 = pointOffsetBaseAngel(points[points.length - 2], p1, 0, head, true);
	let p3 = pointOffsetBaseAngel(points[points.length - 2], p1, 0, neckHeight, true);
	let p4 = pointOffsetBaseAngel(p1, p2, Math.PI / 2, headWidth, false);
	let p5 = pointOffsetBaseAngel(p1, p2, Math.PI / 2, headWidth, true);
	let p6 = pointOffsetBaseAngel(p1, p3, Math.PI / 2, neck, false);
	let p7 = pointOffsetBaseAngel(p1, p3, Math.PI / 2, neck, true);

	let psRet = [];
	psRet.push(p6);
	psRet.push(p4);
	psRet.push(p1);
	psRet.push(p5);
	psRet.push(p7);
	return psRet;
}

function doubleArrow3(points, pt1, pt2, disPow) {
	let dis = path_distance(points);
	let len = pow_99(points);
	let lenDis = len * disPow;
	let dis1 = two_points_distance(pt1, pt2);
	let dis2 = (lenDis - dis1) / 2;
	let dis3 = 0.0;
	let ps1 = [],
		ps2 = [];
	for (let i = 1; i < points.length - 1; i++) {
		let angle = twoLineAngle(points[i - 1], points[i], points[i + 1]) / 2;
		dis3 += two_points_distance(points[i - 1], points[i]);
		let w = (lenDis / 2 - dis3 / dis * dis2) / Math.sin(angle);
		let _f5 = pointOffsetBaseAngel(points[i - 1], points[i], Math.PI - angle, w, true);
		let _f6 = pointOffsetBaseAngel(points[i - 1], points[i], angle, w, false);
		ps1.push(_f5);
		ps2.push(_f6);
	}
	return ps1.concat(ps2);
}

function doubleArrow4(pt1, pt2, pt3) {
	let p1 = center_point_of_twopoints(pt1, pt2);
	let len = two_points_distance(p1, pt3);
	let angle = twoLineAngle(pt1, p1, pt3);
	let p2, mid;
	let len1, len2;
	if (angle < Math.PI / 2) {
		len1 = len * Math.sin(angle);
		len2 = len * Math.cos(angle);
		mid = pointOffsetBaseAngel(pt1, p1, Math.PI / 2, len1, false);
		p2 = pointOffsetBaseAngel(p1, mid, Math.PI / 2, len2, true);
	} else {
		if (angle >= Math.PI / 2 && angle < Math.PI) {
			len1 = len * Math.sin(Math.PI - angle);
			len2 = len * Math.cos(Math.PI - angle);
			mid = pointOffsetBaseAngel(pt1, p1, Math.PI / 2, len1, false);
			p2 = pointOffsetBaseAngel(p1, mid, Math.PI / 2, len2, false);
		} else {
			if (angle >= Math.PI && angle < Math.PI * 1.5) {
				len1 = len * Math.sin(angle - Math.PI);
				len2 = len * Math.cos(angle - Math.PI);
				mid = pointOffsetBaseAngel(pt1, p1, Math.PI / 2, len1, true);
				p2 = pointOffsetBaseAngel(p1, mid, Math.PI / 2, len2, true);
			} else {
				len1 = len * Math.sin(Math.PI * 2 - angle);
				len2 = len * Math.cos(Math.PI * 2 - angle);
				mid = pointOffsetBaseAngel(pt1, p1, Math.PI / 2, len1, true);
				p2 = pointOffsetBaseAngel(p1, mid, Math.PI / 2, len2, false);
			}
		}
	}
	return p2;
}

function combinelNum(n, m) {
	return factorialSequence(n) / (factorialSequence(m) * factorialSequence(n - m));
}

function factorialSequence(n) {
	if (n <= 1) {
		return 1;
	}

	let num = 1;
	for (let i = 1; i <= n; i++) {
		num *= i;
	}
	return num;
}

function scissorPart(ps) {
	if (ps.length <= 2) {
		return ps;
	}
	let psRet = [];
	let n = ps.length - 1;
	for (let t = 0; t <= 1; t += 1.0 / FITTING_COUNT) {
		let lng = 0.0;
		let lat = 0.0;
		for (let m = 0; m <= n; m++) {
			let num = combinelNum(n, m);
			let a = Math.pow(t, m);
			let b = Math.pow((1 - t), (n - m));
			lng += num * a * b * ps[m].lng;
			lat += num * a * b * ps[m].lat;
		}
		psRet.push(LatLng(lat, lng));
	}
	psRet.push(ps[n]);
	return psRet;
}

function generateDoubleArraw(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);
	let pt1 = toLatLng(points[0]);
	let pt2 = toLatLng(points[1]);
	let pt3 = toLatLng(points[2]);
	let tempPoint4;
	if (points.length == 3) {
		tempPoint4 = doubleArrow4(pt1, pt2, pt3);
	} else {
		tempPoint4 = toLatLng(points[3]);
	}
	let connPoint = center_point_of_twopoints(pt1, pt2);
	let pts1, pts2;
	if (twoLineRateCompare(pt1, pt2, pt3)) {
		pts1 = doubleArrow1(pt1, connPoint, tempPoint4, false);
		pts2 = doubleArrow1(connPoint, pt2, pt3, true);
	} else {
		pts1 = doubleArrow1(pt2, connPoint, pt3, false);
		pts2 = doubleArrow1(connPoint, pt1, tempPoint4, true);
	}
	let m = pts1.length;
	let t = (m - 5) / 2;
	let ps1 = pts1.slice(0, t);
	let ps2 = pts1.slice(t, t + 5);
	let ps3 = pts1.slice(t + 5, m);
	let ps4 = pts2.slice(0, t);
	let ps5 = pts2.slice(t, t + 5);
	let ps6 = pts2.slice(t + 5, m);
	ps4 = scissorPart(ps4);
	let ps7 = scissorPart(ps6.concat(ps1.slice(1)));
	ps3 = scissorPart(ps3);
	let psRet = [];
	psRet = psRet.concat(ps4, ps5, ps7, ps2, ps3);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}

function generateThreeArrowLine(points) {
	if (points.length < 3) return points;
	points = latlngs2WebMercators(points);

	let pt1 = toLatLng(points[0]);
	let pt2 = toLatLng(points[1]);
	let pt3 = toLatLng(points[2]);
	let lastPoint, centerPoint;
	lastPoint = doubleArrow4(pt1, pt2, pt3);
	if (points.length == 3) {
		centerPoint = center_point_of_twopoints(lastPoint, pt3);
	} else if (points.length == 4) {
		centerPoint = points[3];
	} else {
		centerPoint = points[3];
		lastPoint = points[4];
	}

	let connPoint = center_point_of_twopoints(pt1, pt2);
	let connPoint1 = center_point_of_twopoints(pt1, connPoint);
	let connPoint2 = center_point_of_twopoints(connPoint, pt2);
	let pts1, pts2, pts3;
	if (twoLineRateCompare(pt1, pt2, pt3)) {
		pts1 = doubleArrow1(pt1, connPoint, lastPoint, false);
		pts2 = doubleArrow1(connPoint, pt2, pt3, true);
		pts3 = doubleArrowCenter(connPoint2, connPoint1, centerPoint);
	} else {
		pts1 = doubleArrow1(pt2, connPoint, pt3, false);
		pts2 = doubleArrow1(connPoint, pt1, lastPoint, true);
		pts3 = doubleArrowCenter(connPoint2, connPoint1, centerPoint);
	}
	let m = pts2.length;
	let t = (m - 5) / 2;
	let ps4 = pts2.slice(0, t); //left_arrow_lside
	let ps5 = pts2.slice(t, t + 5); //left_arrow_top
	let ps6 = pts2.slice(t + 5, m); //left_arrow_rside

	m = pts3.length;
	t = (m - 1) / 2 - 2;
	let ps_m1 = pts3.slice(0, t + 1); //middle_arrow_lside
	let ps_m2 = pts3.slice(t, t + 5); //middle_arrow_top
	let ps_m3 = pts3.slice(t + 4, m); //middle_arrow_rside

	m = pts1.length;
	t = (m - 5) / 2;
	let ps1 = pts1.slice(0, t + 1); //right_arrow_lside
	let ps2 = pts1.slice(t, t + 5); //right_arrow_top
	let ps3 = pts1.slice(t + 5, m); //right_arrow_rside

	let psi1 = scissorPart(ps6.slice(0, ps6.length - 1).concat(ps_m1.slice(1)));
	let psi2 = scissorPart(ps_m3.slice(0, ps_m3.length - 1).concat(ps1.slice(1)));

	ps4 = scissorPart(ps4);

	let ps7 = scissorPart(ps6.concat(ps1.slice(1)));

	ps3 = scissorPart(ps3);

	let psRet = [];
	psRet = psRet.concat(ps4, ps5, psi1, ps_m2, psi2, ps2, ps3);

	psRet = removeRePointvar(psRet);
	psRet = WebMercators2latlons(psRet);
	return psRet;
}
//sxsim add 以上是添加标绘

export {
	generateCurve,
	generateArc,
	generateArrowStraight,
	generateRectangle,
	generateCircle,
	generateEllipse,
	generateLune,
	generateSector,
	generateClosedCurve,
	generateGather,
	generateArrawAssaultDirection,
	generateArrawAttack,
	generateArrawAttackTailled,
	generateArrawFine,
	generateArrawSquadCambat,
	generateArrawTailedSquadCambat,
	generateDoubleArraw,
	generateThreeArrowLine
}