
<template>
    <div class="child-item">
        <span class="title">{{ title }}</span>
        <ColorPicker
            size="small"
            v-model="color"
            alpha
            @on-change="changeColor"
        />
        <span
            v-for="(item, index) in defaultColorList"
            :key="index"
            class="color-default"
            :style="`background:${item}`"
            @click="changeDefaultColor(item)"
        ></span>
    </div>
</template>
<script>
import common from "@/assets/js/common.js";
export default {
    props: {
        title: {
            type: String,
            default: "颜色"
        },
        curColor: {
            type: String,
            default: "rgba(25, 190,107, 1.0)"
        }
    },
    data() {
        return {
            defaultColorList: [
                "rgba(24, 93, 178,1.0)",
                "rgba(70, 106, 216,1.0)",
                "rgba(208, 135, 19,1.0)",
                "rgba(232, 81, 145,1.0)",
                "rgba(131, 182, 230,1.0)",
                "rgba(32, 176, 32,1.0)"
            ],
            color: ""
        };
    },
    mounted() {
        this.color = this.curColor;
    },
    methods: {
        changeColor(e) {
            let color = common.rgbaToCesiumColor(e);
            this.$emit("func", color);
        },
        changeDefaultColor(item) {
            this.color = item;
            this.changeColor(item);
        }
    },
    watch: {
        curColor(val) {
            this.color = val;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/chileStyleItem.scss";
.color-default {
    cursor: pointer;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 5px;
    border-radius: 5px;
}
</style>