import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/iview.js'
import axios from 'axios'
import 'font-awesome/css/font-awesome.css'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

// 导入用到的图标
import {
    faTrash,
    faLocationArrow,
    faEye
} from '@fortawesome/free-solid-svg-icons'
library.add(faTrash, faLocationArrow, faEye)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import kiContext from '@kiyoaki_w/vue-context'
Vue.use(kiContext)

Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')