/*
画图事件的基类
*/
import {
    MouseHandle
} from "./mouseHandle.js"

var uuid = require('uuid');

import {
    createControlPoint,
    createLabel
} from './entities'

import {
    Cartesian3 as CesiumCartesian3
} from "cesium/Cesium";

class DrawHandle extends MouseHandle {
    constructor(viewer, options = null) {
        if (!options.hasOwnProperty('controlPoint'))
            options.controlPoint = -1
        if (!options.hasOwnProperty('lineStyle'))
            options.lineStyle = ''
        if (!options.hasOwnProperty('polyStyle'))
            options.polyStyle = ''

        //super关键字等价于父类构造方法
        super(viewer, options);
        this.closeEdit();

        this.controlPointPrimitiveList = []
        this.uuidList = []
        this.createLabelId = null;
    }

    onLeftClick(e) {
        if (this.options.endDraw) return false;
        let position = this.getMousePoint(e);
        if (position) {
            this.addControlPointList(position);
            if (this.positions.length == 0) {
                this.positions.push(position)
            }
            this.positions.push(position)
            return true
        }
        return false;
    }

    onMove(e) {
        this.setMouseStyle()
        let position = this.getMousePoint(e, 'move');
        if (position) {
            this.addLabel(position) //添加标签
            if (this.positions.length > 1) {
                this.positions.pop();
                this.positions.push(position)
                return true;
            }
        }
        return false;
    }

    onDoubleClick(e) {
        if (this.positions.length > 1) {
            this.positions.splice(this.positions.length - 2, 2)
            this.clearPoints();
            return true;
        }
        return false;
    }

    clearPoints() {
        this.positions = [];
        this.cartesianPositions = [];
        if (!this.options.hasOwnProperty('workMode')) {
            this.deleteControlPointList()
        }

    }

    //鼠标右键结束
    onRightClick(e) {
        this.positions = [];
        this.options.endCallback();
        this.endDrawHandle();
    }

    //结束事件
    endDrawHandle() {
        this.options.endDraw = true;
        this.setMouseStyle();
        if (this.handler) {
            this.handler.destroy();
            if (this.createLabelId) this.viewer.entities.remove(this.viewer.entities.getById(this.createLabelId)) //删除标签
        }
        this.openEdit();
    }

    //设置鼠标样式
    setMouseStyle() {
        if (this.options.endDraw) {
            let cesiumContainer = document.getElementById("cesiumContainer");
            cesiumContainer.style.cursor = "default";
        } else {
            let cesiumContainer = document.getElementById("cesiumContainer");
            cesiumContainer.style.cursor = "crosshair";
        }
    }
    openEdit() {
        if (this.options.editGraphic) {
            this.options.editGraphic.options.editMode = true
        }
    }
    closeEdit() {
        if (this.options.editGraphic) {
            this.options.editGraphic.options.editMode = false
        }
    }
    addControlPointList(position) {
        if (this.positions.length == 0) {
            this.uuidList = []
        }
        let cartesianPosition = CesiumCartesian3.fromDegrees(position.lng, position.lat)
        let id = uuid.v1()
        this.uuidList.push(id)
        let ControlPointPrimitive = createControlPoint(this, id)
        this.controlPointPrimitiveList.push(new ControlPointPrimitive(cartesianPosition));
    }

    addLabel(position) {
        let cartesianPosition = CesiumCartesian3.fromDegrees(position.lng, position.lat)
        if (!this.createLabelPrimitive) {
            let text = '鼠标左键双击结束';
            if (this.options.controlPoint > 0) {
                text = `鼠标左键点击${this.options.controlPoint}下自动结束`
            }
            this.laberPosition = cartesianPosition
            this.createLabelId = uuid.v1()
            let CreateLabelPrimitive = createLabel(this, this.createLabelId, text)
            this.createLabelPrimitive = new CreateLabelPrimitive(this.laberPosition)
        } else {
            this.laberPosition.x = cartesianPosition.x;
            this.laberPosition.y = cartesianPosition.y;
            this.laberPosition.z = cartesianPosition.z;
        }
    }

    //根据点击的位置添加标签
    addClickLabel(position, text) {
        let cartesianPosition = CesiumCartesian3.fromDegrees(position.lng, position.lat)
        let CreateLabelPrimitive = createLabel(this, uuid.v1(), text, 'click')
        new CreateLabelPrimitive(cartesianPosition)
    }

    //删除控制点
    deleteControlPointList() {
        this.uuidList.forEach(element => {
            this.viewer.entities.remove(this.viewer.entities.getById(element))
        });
    }

    //更新文字
    updataLabelText(text) {
        if (this.createLabelPrimitive) {
            let entity = this.viewer.entities.getById(this.createLabelId)
            entity._label._text._value = text;
        }
    }
}

export {
    DrawHandle
}