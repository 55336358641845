<template>
  <div
    class="earth-view"
    @click="hideContextMenu()"
    @contextmenu.prevent="showContextMenu($event)"
  >
    <el-container>
      <LeftNavigation :heightAside="heightAside" :widthApp="widthApp" />
      <div id="cesiumContainer"></div>
      <RightStyle
        :heightAside="heightAside"
        :bufferInfo="bufferInfo"
        v-if="isEditing"
      />
      <Search />
      <RightTool :heightAside="heightAside" :widthApp="widthApp" />
    </el-container>

    <!-- 在适当位置插入ki-context组件 -->
    <ki-context
      ref="kiContext"
      minWidth="1em"
      maxWidth="15em"
      backgroundColor="#fbfbfb"
      fontSize="15px"
      textColor="#35495e"
      iconColor="#409EFF"
      borderRadius="0.1"
    />

    <Modal
      title="添加视点"
      v-model="isShowDlg"
      :mask-closable="false"
      @on-ok="addViewPointToList"
    >
      <p>
        视点名称：<Input
          v-model="viewPointName"
          placeholder="请输入视点名称"
          style="width: 400px"
        />
      </p>
    </Modal>
  </div>
</template>
<script>
import common from "@/assets/js/common.js";
// 局部组件引用
import {
  Viewer,
  Ion,
  ScreenSpaceEventType,
  UrlTemplateImageryProvider,
  WebMercatorTilingScheme,
  EllipsoidTerrainProvider,
  Rectangle as CesiumRectangle,
  Cartesian3 as CesiumCartesian3,
} from "cesium/Cesium";
// noinspection ES6UnusedImports
import widget from "cesium/Widgets/widgets.css";
import CesiumNavigation from "cesium-navigation-es6"; //导航插件

import LeftNavigation from "./LeftNavigation.vue";
import RightStyle from "./draw/RightStyle.vue";
import Search from "./Search.vue";
import RightTool from "./RightTool.vue";

import { EditGraphic } from "@/assets/js/map/editGraphic.js";

export default {
  name: "CesiumContainer",
  components: {
    LeftNavigation,
    RightStyle,
    Search,
    RightTool,
  },
  data() {
    return {
      viewer: null,
      isEditing: false,
      heightAside: 0,
      widthApp: 600,
      bufferInfo: {},
      currentEntity: null,
      currentPosition: null,
      isShowDlg: false,
      viewPointName: "",
      currentViewPoint: {},
    };
  },
  mounted() {
    this.$store.state.viewPointList = [];
    this.doProhibit(); //禁用系统右键菜单
    this.$nextTick(() => {
      this.cesiumInit();
    });

    // 然后监听window的resize事件．在浏览器窗口变化时再设置高度．
    const that = this;
    that.setReChessBoardSize();
    window.onresize = function temp() {
      that.setReChessBoardSize();
    };
  },
  methods: {
    setReChessBoardSize() {
      let appRect = document.getElementById("app");
      let heightNum = appRect.offsetHeight;
      this.heightAside = heightNum - 55; //滚动区域的高度
      this.widthApp = appRect.offsetWidth;
    },
    cesiumInit() {
      Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4ZDUxNmQzYS00ZGIzLTRhMDktYTY2Yi1lOGYwZTFiOGE3YzAiLCJpZCI6MTY2MjUsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzA4NDkyMjB9.oDSM6BtPF6wkkoW4hIqORs64UGyqflO8Q0JT0nmdL2g";
      let viewer = new Viewer("cesiumContainer", {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: false, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
        scene3DOnly: true, //如果设置为true，则所有几何图形以3D模式绘制以节约GPU资源
        showRenderLoopErrors: false,
        requestRenderMode: false,
      });
      this.$store.state.viewer = viewer;
      this.viewer = viewer;
      viewer._cesiumWidget._creditContainer.style.display = "none"; //去掉版权信息

      // 开启全球光照
      //viewer.scene.globe.enableLighting = true;

      // 删除默认的影像图层
      viewer.imageryLayers.remove(viewer.imageryLayers.get(0));

      //默认的影像层
      let defaultLayer = new UrlTemplateImageryProvider({
        url:
          "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer//tile/{z}/{y}/{x}",
        subdomains: "abc",
        tilingScheme: new WebMercatorTilingScheme(),
      });
      // 增加默认影像层
      viewer.imageryLayers.addImageryProvider(defaultLayer);

      //默认的标记层
      let defaultOverlayLayer = new UrlTemplateImageryProvider({
        url:
          "https://t{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={z}&TileRow={y}&TileCol={x}&style=default&format=tiles&tk=3a906e6382a271262787b28398c7ce43",
        subdomains: "01234567",
        tilingScheme: new WebMercatorTilingScheme(),
      });
      //增加默认标记层
      viewer.imageryLayers.addImageryProvider(defaultOverlayLayer);

      //默认没有加载地形
      viewer.terrainProvider = new EllipsoidTerrainProvider({});
      // 打开深度检测，那么在地形以下的对象不可见
      //viewer.scene.globe.depthTestAgainstTerrain = false;

      //显示刷新率和帧率
      //viewer.scene.debugShowFramesPerSecond = true;

      this.$store.state.layerIds = {
        base: [6],
        overlay: [2],
        terrain: [1],
      };
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      ); //屏蔽 默认会对场景中添加的entity进行双击追踪效果

      //打开图形编辑模式
      this.$store.state.editGraphic = new EditGraphic(viewer, {
        editMode: true,
        isEditingCallback: (data) => {
          this.isEditing = data;
        },
        getBufferInfo: (data) => {
          this.bufferInfo = data;
        },
        getCurrentObj: (data) => {
          this.currentEntity = data.entity;
          this.currentPosition = data.position;
        },
      });
      common.setHomeViewPoint(viewer);

      let navigationOptions = {};
      // 用于启用或禁用罗盘。true是启用罗盘，false是禁用罗盘。默认值为true。如果将选项设置为false，则罗盘将不会添加到地图中。
      navigationOptions.enableCompass = true;
      // 用于启用或禁用缩放控件。true是启用，false是禁用。默认值为true。如果将选项设置为false，则缩放控件将不会添加到地图中。
      navigationOptions.enableZoomControls = false;
      // 用于启用或禁用距离图例。true是启用，false是禁用。默认值为true。如果将选项设置为false，距离图例将不会添加到地图中。
      navigationOptions.enableDistanceLegend = true;
      // 用于启用或禁用指南针外环。true是启用，false是禁用。默认值为true。如果将选项设置为false，则该环将可见但无效。
      navigationOptions.enableCompassOuterRing = true;

      CesiumNavigation(viewer, navigationOptions);
    },
    showContextMenu(event) {
      if (!this.currentPosition) return;
      let items = [
        {
          icon: "eye",
          text: "添加视点",
          divider: true,
          click: () => {
            this.addViewPoint();
          },
        },
      ];
      if (this.currentEntity) {
        let id = this.currentEntity._id;
        // 在items数组中定义所需的右键菜单选项
        items.push({
          icon: "trash",
          text: "删除",
          divider: true,
          click: () => {
            this.deleteEntity(id);
          },
        });
      }
      this.$refs.kiContext.show(event, items);
    },
    hideContextMenu() {
      this.$refs.kiContext.hide();
    },
    addViewPoint() {
      let ps = this.viewer.camera.position;
      let destination = new CesiumCartesian3(ps.x, ps.y, ps.z); //this.viewer.camera.position;

      let orientation = {
        heading: this.viewer.camera.heading,
        pitch: this.viewer.camera.pitch,
        roll: this.viewer.camera.roll,
      };
      this.currentViewPoint = {
        orientation,
        destination,
      };
      this.isShowDlg = true;
    },
    addViewPointToList() {
      this.$store.state.viewPointList.push({
        name: this.viewPointName,
        destination: this.currentViewPoint.destination,
        orientation: this.currentViewPoint.orientation,
      });
      this.isShowDlg = false;
    },
    deleteEntity(id) {
      let entity = this.viewer.entities.getById(id);
      if (!entity) return;
      if (entity.hasOwnProperty("_workMode")) {
        common.clearEntyties("_workMode", this.viewer);
      } else {
        if (entity.hasOwnProperty("uuidList")) {
          entity.uuidList.forEach((element) => {
            this.viewer.entities.remove(this.viewer.entities.getById(element));
          });
        }
        this.viewer.entities.remove(entity);
      }
    },
    doProhibit() {
      if (window.Event) document.captureEvents(Event.MOUSEUP);

      function nocontextmenu() {
        event.cancelBubble = true;
        event.returnvalue = false;
        return false;
      }

      function norightclick(e) {
        if (window.Event) {
          if (e.which == 2 || e.which == 3) return false;
        } else if (event.button == 2 || event.button == 3) {
          event.cancelBubble = true;
          event.returnvalue = false;
          return false;
        }
      }
      document.oncontextmenu = nocontextmenu; // for IE5+
      document.onmousedown = norightclick; //
    },
  },
};
</script>

<style lang="less" scoped>
.earth-view {
  width: 100%;
  height: 100%;
}
#cesiumContainer {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.el-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
