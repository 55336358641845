<template>
    <div>
        <div class="pop-up">
            <div class="title">点标记</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in pointList"
                    :key="index"
                    class="box"
                    @click="darwPoint(item)"
                    :class="{
                        'active-box': item.active,
                        'box-small': isSmallScreen
                    }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                </div>
            </div>
        </div>
        <div class="split-item-line"></div>
        <div class="pop-up">
            <div class="title">线标记</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in lineList"
                    :key="index"
                    class="box"
                    @click="darwLine(item)"
                    :class="{
                        'active-box': item.active,
                        'box-small': isSmallScreen
                    }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                </div>
            </div>
        </div>
        <div class="split-item-line"></div>
        <div class="pop-up">
            <div class="title">面标记</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in areaList"
                    :key="index"
                    class="box"
                    @click="darwArea(item)"
                    :class="{
                        'active-box': item.active,
                        'box-small': isSmallScreen
                    }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                </div>
            </div>
        </div>
        <div class="split-item-line"></div>
        <div class="pop-up">
            <!-- <div class="title">加载矢量数据</div> -->
        </div>
    </div>
</template>

<script>
import { Marker } from "@/assets/js/map/marker.js";
import { Polyline } from "@/assets/js/map/polyline.js";
import { Polygon } from "@/assets/js/map/polygon.js";
import { radeShapefile } from "@/assets/js/readJson.js";

import {
    Color as CesiumColor,
    Cartesian2 as CesiumCartesian2,
    Cartesian3 as CesiumCartesian3,
    Math as CesiumMath,
    CallbackProperty as CesiumCallbackProperty,
    LabelStyle as CesiumLabelStyle,
    VerticalOrigin as CesiumVerticalOrigin,
    HorizontalOrigin as CesiumHorizontalOrigin,
    HeightReference as CesiumHeightReference,
    ArcType as CesiumArcType
} from "cesium/Cesium";

export default {
    props: {
        isSmallScreen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            viewer: null,
            editGraphic: null,
            markerHandle: null,
            polyLineHandle: null,
            polygonHandle: null,
            pointList: [
                {
                    id: 1,
                    title: "点标记",
                    img: "./images/draw/draw_point.png",
                    active: false
                }
            ],
            lineList: [
                {
                    id: 1,
                    title: "折线",
                    controlPoint: -1,
                    lineStyle: "",
                    img: "./images/draw/draw_line.png",
                    active: false
                },
                {
                    id: 2,
                    title: "曲线",
                    controlPoint: -1,
                    lineStyle: "curve",
                    img: "./images/draw/draw_curve.png",
                    active: false
                },
                {
                    id: 3,
                    title: "圆弧",
                    controlPoint: 3,
                    lineStyle: "arc",
                    img: "./images/draw/draw_arc.png",
                    active: false
                },
                {
                    id: 4,
                    title: "箭头",
                    controlPoint: 2,
                    lineStyle: "arrow_straight",
                    img: "./images/draw/draw_arrowStraight.png",
                    active: false
                }
            ],
            areaList: [
                {
                    id: 1,
                    title: "多边形",
                    controlPoint: -1,
                    polyStyle: "",
                    img: "./images/draw/draw_polygon.png",
                    active: false
                },
                {
                    id: 2,
                    title: "矩形",
                    controlPoint: 3,
                    polyStyle: "rectangle",
                    img: "./images/draw/draw_Rectangle.png",
                    active: false
                },
                {
                    id: 3,
                    title: "圆形",
                    controlPoint: 2,
                    polyStyle: "circle",
                    img: "./images/draw/draw_circle.png",
                    active: false
                },
                {
                    id: 4,
                    title: "椭圆",
                    controlPoint: 3,
                    polyStyle: "ellipse",
                    img: "./images/draw/draw_ellipse.png",
                    active: false
                },
                {
                    id: 5,
                    title: "弓形",
                    controlPoint: 3,
                    polyStyle: "lune",
                    img: "./images/draw/draw_lune.png",
                    active: false
                },
                {
                    id: 6,
                    title: "扇形",
                    controlPoint: 3,
                    polyStyle: "sector",
                    img: "./images/draw/draw_sector.png",
                    active: false
                },
                {
                    id: 7,
                    title: "闭合曲线",
                    controlPoint: -1,
                    polyStyle: "curve_close_area",
                    img: "./images/draw/draw_curveCloseArea.png",
                    active: false
                },
                {
                    id: 8,
                    title: "集结地",
                    controlPoint: 3,
                    polyStyle: "gather",
                    img: "./images/draw/draw_gather.png",
                    active: false
                },
                {
                    id: 9,
                    title: "突击方向",
                    controlPoint: 2,
                    polyStyle: "arraw_assault_direction",
                    img: "./images/draw/draw_arrawAssaultDirection.png",
                    active: false
                },
                {
                    id: 10,
                    title: "进攻方向",
                    controlPoint: -1,
                    polyStyle: "arraw_attack",
                    img: "./images/draw/draw_arrawAttack.png",
                    active: false
                },
                {
                    id: 11,
                    title: "进攻方向(尾)",
                    controlPoint: -1,
                    polyStyle: "arraw_attack_tailled",
                    img: "./images/draw/draw_arrawAttackTailled.png",
                    active: false
                },
                {
                    id: 12,
                    title: "细直箭头",
                    controlPoint: 2,
                    polyStyle: "arraw_fine",
                    img: "./images/draw/draw_arrawFine.png",
                    active: false
                },
                {
                    id: 13,
                    title: "分队战斗行动",
                    controlPoint: -1,
                    polyStyle: "arraw_squad_cambat",
                    img: "./images/draw/draw_arrawSquadCambat.png",
                    active: false
                },
                {
                    id: 14,
                    title: "分队战斗行动(尾)",
                    controlPoint: -1,
                    polyStyle: "arraw_tailed_squad_cambat",
                    img: "./images/draw/draw_arrawTailedSquadCambat.png",
                    active: false
                },
                {
                    id: 15,
                    title: "钳击",
                    controlPoint: 4,
                    polyStyle: "double_arraw",
                    img: "./images/draw/draw_doubleArraw.png",
                    active: false
                },
                {
                    id: 16,
                    title: "攻击驱散",
                    controlPoint: 5,
                    polyStyle: "three_arrow",
                    img: "./images/draw/draw_threeArrowLine.png",
                    active: false
                }
            ],
            //点标记的初始化样式
            markerStyle: {
                billboard: {
                    show: true, // default
                    image: "./images/marker/5.png",
                    scale: 1.0,
                    pixelOffset: new CesiumCartesian2(0.0, -20.5),
                    color: CesiumColor.WHITE,
                    //rotation: Math.PI, //图标旋转弧度计算
                    //alignedAxis: CesiumCartesian3.UNIT_Y,
                    distanceDisplayCondition: Number.POSITIVE_INFINITY //防止被裁剪
                },
                label: {
                    text: "点标记",
                    font: "normal normal 16px 微软雅黑", //字体样式
                    fillColor: CesiumColor.WHITE, //字体颜色
                    backgroundColor: CesiumColor.AQUA, //背景颜色
                    showBackground: false, //是否显示背景颜色
                    style: CesiumLabelStyle.FILL, //label样式
                    scale: 1.0,
                    verticalOrigin: CesiumVerticalOrigin.CENTER, //垂直位置
                    horizontalOrigin: CesiumHorizontalOrigin.LEFT, //水平位置
                    pixelOffset: new CesiumCartesian2(20, -20.5), //偏移
                    distanceDisplayCondition: Number.POSITIVE_INFINITY //防止被裁剪
                }
            },
            //线标记的初始化样式
            polylineStyle: {
                polyline: {
                    show: true,
                    positions: [],
                    material: CesiumColor.DARKORANGE,
                    arcType: CesiumArcType.GEODESIC,
                    clampToGround: true,
                    width: 2
                }
            },
            //面标记的初始化样式
            polygonStyle: {
                polygon: {
                    show: true,
                    hierarchy: {},
                    material: CesiumColor.CORNFLOWERBLUE,
                    arcType: CesiumArcType.GEODESIC,
                    fill: true,
                    outline: false,
                    outlineColor: CesiumColor.CHOCOLATE,
                    outlineWidth: 2.0,
                    heightReference: CesiumHeightReference.CLAMP_TO_GROUND
                }
            }
        };
    },
    mounted() {
        this.viewer = this.$store.state.viewer;
        this.editGraphic = this.$store.state.editGraphic;
    },
    destroyed() {
        this.clearDrawHandle();
    },
    methods: {
        darwPoint(item) {
            this.clearDrawHandle();
            this.setItemActive(item);
            if (this.endDraw(item, this.markerHandle)) return;
            this.markerHandle = new Marker(this.viewer, {
                endDraw: !item.active,
                markerStyle: this.markerStyle,
                endCallback: () => {
                    item.active = false;
                    this.markerHandle = null;
                }
            });
        },
        darwLine(item) {
            this.clearDrawHandle();
            this.setItemActive(item);
            if (this.endDraw(item, this.polyLineHandle)) return;
            this.polyLineHandle = new Polyline(this.viewer, {
                endDraw: !item.active,
                polylineStyle: this.polylineStyle,
                lineStyle: item.lineStyle,
                controlPoint: item.controlPoint,
                editGraphic: this.editGraphic,
                endCallback: () => {
                    item.active = false;
                    this.polyLineHandle = null;
                }
            });
        },
        darwArea(item) {
            this.clearDrawHandle();
            this.setItemActive(item);
            if (this.endDraw(item, this.polygonHandle)) return;
            this.polygonHandle = new Polygon(this.viewer, {
                endDraw: !item.active,
                polygonStyle: this.polygonStyle,
                polyStyle: item.polyStyle,
                controlPoint: item.controlPoint,
                editGraphic: this.editGraphic,
                endCallback: () => {
                    item.active = false;
                    this.polygonHandle = null;
                }
            });
        },
        setItemActive(item) {
            let active = item.active;
            let clearItem = list => {
                for (let it of list) {
                    it.active = false;
                }
            };
            clearItem(this.pointList);
            clearItem(this.lineList);
            clearItem(this.areaList);
            item.active = !active;
        },
        endDraw(item, handle) {
            if (!item.active) {
                this.endDrawHandle(handle);
                return true;
            }
            return false;
        },
        endDrawHandle(handle) {
            if (handle) handle.endDrawHandle();
        },
        clearDrawHandle() {
            this.endDrawHandle(this.markerHandle);
            this.endDrawHandle(this.polyLineHandle);
            this.endDrawHandle(this.polygonHandle);
            this.markerHandle = null;
            this.polyLineHandle = null;
            this.polygonHandle = null;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../../assets/css/leftNavigation.less";
.box {
    width: 15%;
    height: 40px;
    .img-flag {
        width: 100%;
        height: 90%;
        text-align: center;
        img {
            margin-top: 5%;
            border-radius: 5px;
            width: 90%;
            height: 95%;
        }
    }
}
</style>
