/*
获取鼠标的当前位置
*/
import {
	MouseHandle
} from "./mouseHandle.js"

class MousePosition extends MouseHandle {
	constructor(viewer, options = null) {
		//super关键字等价于父类构造方法
		super(viewer, options);
		this.init()
	}
	init() {
		this.position = null;
	}
	onMove(e) {
		this.position = this.getMousePoint(e,'move');
		this.sendData();
	}
	onWheel(e) {		
		this.sendData();
	}
	sendData(){
		if(this.position){
			//获取相机高度
			let height = Math.ceil(this.viewer.camera.positionCartographic.height);
			let curMousesPostion = '经度：' + this.position.lng.toFixed(6) + '° 纬度：' + this.position.lat.toFixed(6) + "° 海拔：" + this.position.alt + "米 视角海拔：";
			if(height > 1000){				
				curMousesPostion += Math.round(height/1000) + "千米";	
			}
			else{
				curMousesPostion += height + "米";				
			}
			this.options.callback(curMousesPostion)
		}
	}
}

export {
	MousePosition
}
