<template>
    <div class="search-place">
        <el-autocomplete
            class="inline-input"
            v-model="place"
            :fetch-suggestions="querySearch"
            placeholder="请输入地名"
            :trigger-on-focus="false"
            @select="handleSelect"
            clearable
            size="small"
            @clear="clearPlace"
            @keyup.enter.native="startSearch"
        ></el-autocomplete>
        <el-button
            size="small"
            icon="el-icon-search"
            type="info"
            plain
            style="margin-left:5px;"
            @click="startSearch"
            >搜索</el-button
        >
    </div>
</template>

<script>
import { searchPlace } from "@/assets/js/readJson.js";
import { addPlaceToMap } from "@/assets/js/map/entities.js";

import common from "@/assets/js/common.js";

export default {
    data() {
        return {
            place: "",
            searchResult: []
        };
    },
    mounted() {},
    methods: {
        querySearch(queryString, cb) {
            searchPlace(queryString, (err, data) => {
                this.searchResult = [];
                if (!err) {
                    cb(data);
                    this.searchResult = data;
                } else {
                    console.log(err);
                }
            });
        },
        handleSelect(item) {
            this.clearPlace();
            this.searchResult = [];
            addPlaceToMap(this.viewer, [item]);
        },
        startSearch() {
            if (this.searchResult.length == 0) return;
            this.clearPlace();
            addPlaceToMap(this.viewer, this.searchResult);
        },
        //清空搜索到的地方
        clearPlace() {
            common.clearEntyties("_searchflag", this.viewer);
        }
    },
    computed: {
        viewer() {
            if (this.$store.state.viewer) {
                return this.$store.state.viewer;
            }
            return null;
        }
    }
};
</script>

<style lang="scss" scoped>
.search-place {
    position: absolute;
    width: 300px;
    height: 40px;
    right: 10px;
    z-index: 2;
    display: flex;
    align-items: center;
}
</style>