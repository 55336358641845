/*
画折线
*/
import {
    DrawHandle
} from "./drawHandle.js"

import {} from './drawGraphic.js'

import {
    createPolyLine
} from './entities'
import {
    defined as Cesiumdefined
} from "cesium/Cesium";

class Polyline extends DrawHandle {
    constructor(viewer, options = null) {
        //super关键字等价于父类构造方法
        super(viewer, options);
        this.polyline = undefined;
    }
    onLeftClick(e) {
        if (!super.onLeftClick(e)) return false;
        if (this.options.controlPoint > 0 && (this.options.controlPoint + 1) == this.positions.length) {
            this.positions.splice(this.positions.length - 1, 1)
            this.clearPoints()
            this.polyline = undefined;
        }
        return true;
    }
    onMove(e) {
        if (!super.onMove(e)) return false;
        this.addPointToPolyLine()
        return true;
    }
    onDoubleClick(e) {
        if (!super.onDoubleClick(e)) return false;
        this.polyline = undefined;
        return true;
    }
    onRightClick(e) {
        this.polyline = undefined;
        super.onRightClick(e)
    }
    endDrawHandle() {
        super.endDrawHandle()
        if (this.polyline) {
            this.polyline = undefined;
            this.clearPoints();
        }
    }
    addPointToPolyLine() {
        this.getGeneratePoints(this.options);
        let PolyLinePrimitive = createPolyLine(this)
        if (!Cesiumdefined(this.polyline)) {
            this.polyline = new PolyLinePrimitive(this.cartesianPositions);
        }
    }
}

export {
    Polyline
}