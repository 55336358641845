/**
 *创建点线面 
 */

import {
    Color as CesiumColor,
    Cartesian2 as CesiumCartesian2,
    Math as CesiumMath,
    CallbackProperty as CesiumCallbackProperty,
    LabelStyle as CesiumLabelStyle,
    VerticalOrigin as CesiumVerticalOrigin,
    HorizontalOrigin as CesiumHorizontalOrigin
} from "cesium/Cesium";

//创建控制点
function createControlPoint(page, id) {
    let _this = page;
    let color = CesiumColor.LIME
    let outlineColor = CesiumColor.BLACK
    let outlineWidth = 0
    let pixelSize = 8
    if (_this.options.hasOwnProperty('controlPointColor')) {
        color = CesiumColor.WHITE
        outlineColor = _this.options.controlPointColor
        outlineWidth = 2
        pixelSize = 6
    }
    return (function() {
        function _(position) {
            this.options = {
                id,
                position: [],
                controlPoint: true,
                point: {
                    color, //点位颜色
                    outlineColor,
                    outlineWidth,
                    pixelSize, //像素点大小
                    show: true
                }
            };
            if (_this.options.hasOwnProperty('workMode')) {
                this.options.workMode = _this.options.workMode;
            }
            this.position = position;
            this._init();
        }
        _.prototype._init = function() {
            let _self = this;
            let _update = function() {
                return _self.position;
            };
            //实时更新polyline.positions
            this.options.position = new CesiumCallbackProperty(_update, false);
            _this.viewer.entities.add(this.options);
        };
        return _;
    })();
}
//创建文本框
function createLabel(page, id, text, flag = 'move') {
    let _this = page;
    return (function() {
        function _(position) {
            this.options = {
                id,
                position: [],
                label: {
                    text,
                    font: "normal normal 10px 微软雅黑", //字体样式
                    fillColor: CesiumColor.BLACK, //字体颜色
                    backgroundColor: CesiumColor.WHITE, //背景颜色
                    showBackground: true, //是否显示背景颜色
                    style: CesiumLabelStyle.FILL_AND_OUTLINE, //label样式
                    scale: 1.0,
                    outlineColor: CesiumColor.RED,
                    outlineWidth: 0,
                    verticalOrigin: CesiumVerticalOrigin.CENTER, //垂直位置
                    horizontalOrigin: CesiumHorizontalOrigin.LEFT, //水平位置
                    pixelOffset: new CesiumCartesian2(10, flag == 'move' ? 20 : 0), //偏移
                    distanceDisplayCondition: Number.POSITIVE_INFINITY //防止被裁剪
                }
            };
            if (_this.options.hasOwnProperty('workMode')) {
                this.options.workMode = _this.options.workMode;
            }
            this.position = position;
            this._init();
        }
        _.prototype._init = function() {
            let _self = this;
            let _update = function() {
                return _self.position;
            };
            //实时更新polyline.positions
            this.options.position = new CesiumCallbackProperty(_update, false);
            _this.viewer.entities.add(this.options);
        };
        return _;
    })();
}
//创建标记
function createMarker(page, bufferInfo = null) {
    let _this = page;
    let options = bufferInfo ? bufferInfo.options : _this.options
    return (function() {
        function _(position) {
            this.options = {
                position: [],
                //自定义缓存信息
                bufferInfo: {
                    type: 'marker',
                    position: _this.position,
                    options: options
                },
                billboard: options.markerStyle.billboard,
                label: options.markerStyle.label
            };
            if (bufferInfo) this.options.id = bufferInfo.id
            this.position = position;
            this._init();
        }
        _.prototype._init = function() {
            let _self = this;
            let _update = function() {
                return _self.position;
            };
            //实时更新polyline.positions
            this.options.position = new CesiumCallbackProperty(_update, false);
            _this.viewer.entities.add(this.options);
        };
        return _;
    })();
}

//创建线标记
function createPolyLine(page, bufferInfo = null) {
    let _this = page;
    let options = bufferInfo ? bufferInfo.options : _this.options;
    return (function() {
        function _(positions) {
            this.options = {
                //自定义缓存信息
                bufferInfo: {
                    type: 'polyline',
                    positions: _this.positions,
                    options: options
                },
                polyline: options.polylineStyle.polyline
            };
            if (_this.options.hasOwnProperty('workMode')) {
                this.options.workMode = _this.options.workMode;
            }
            if (bufferInfo) this.options.id = bufferInfo.id
            this.positions = positions;
            this._init();
        }
        _.prototype._init = function() {
            let _self = this;
            let _update = function() {
                return _self.positions;
            };
            //实时更新polyline.positions
            this.options.polyline.positions = new CesiumCallbackProperty(_update, false);
            _this.viewer.entities.add(this.options);
        };
        return _;
    })();
}
//创建面标记
function createPolygon(page, bufferInfo = null) {
    let _this = page;
    let options = bufferInfo ? bufferInfo.options : _this.options;
    return (function() {
        function _(positions) {
            this.options = {
                //自定义缓存信息
                bufferInfo: {
                    name: 'polygon',
                    type: 'polygon',
                    positions: _this.positions,
                    options: options
                },
                polygon: options.polygonStyle.polygon
            };
            if (_this.options.hasOwnProperty('workMode')) {
                this.options.workMode = _this.options.workMode;
            }
            if (bufferInfo) this.options.id = bufferInfo.id
            this.hierarchy = {
                positions
            };
            this._init();
        }

        _.prototype._init = function() {
            let _self = this;
            let _update = function() {
                return _self.hierarchy;
            };
            //实时更新polygon.hierarchy
            this.options.polygon.hierarchy = new CesiumCallbackProperty(_update, false);
            _this.viewer.entities.add(this.options);
        };
        return _;
    })();
}

function addPlaceToMap(viewer, places) {
    let entitieList = []
    for (let place of places) {
        if (place.hasOwnProperty('cartesian')) {
            let entitie = viewer.entities.add({
                searchflag: true, //自定义搜索的标志
                position: place.cartesian,
                billboard: {
                    show: true, // default
                    image: "./images/marker/9.png",
                    scale: 1.0,
                    pixelOffset: new CesiumCartesian2(0.0, -20.5),
                    color: CesiumColor.WHITE,
                    //rotation: Math.PI, //图标旋转弧度计算
                    //alignedAxis: CesiumCartesian3.UNIT_Y,
                    distanceDisplayCondition: Number.POSITIVE_INFINITY //防止被裁剪
                },
                label: {
                    text: place.value,
                    font: "normal normal 16px 微软雅黑", //字体样式
                    fillColor: CesiumColor.WHITE, //字体颜色
                    backgroundColor: CesiumColor.AQUA, //背景颜色
                    showBackground: false, //是否显示背景颜色
                    style: CesiumLabelStyle.FILL, //label样式
                    scale: 1.0,
                    verticalOrigin: CesiumVerticalOrigin.CENTER, //垂直位置
                    horizontalOrigin: CesiumHorizontalOrigin.LEFT, //水平位置
                    pixelOffset: new CesiumCartesian2(20, -20.5), //偏移
                    distanceDisplayCondition: Number.POSITIVE_INFINITY //防止被裁剪
                }
            });
            entitieList.push(entitie)
        }
    }
    if (entitieList.length > 0) {
        viewer.flyTo(entitieList[0], {
            offset: {
                heading: CesiumMath.toRadians(0.0),
                pitch: CesiumMath.toRadians(-90),
                range: 500
            }
        });
    }
}

export {
    createControlPoint,
    createLabel,
    createMarker,
    createPolyLine,
    createPolygon,
    addPlaceToMap
}