<template>
    <div>
        <div class="child-item">
            <Checkbox v-model="lighting" @click.native="setLighting"
                >全球光照</Checkbox
            >
        </div>
        <div class="child-item">
            <Checkbox v-model="depthTest" @click.native="setDepthTest"
                >深度测试</Checkbox
            >
        </div>
        <div class="child-item">
            <Checkbox v-model="showFrames" @click.native="setShowFrames"
                >显示帧率</Checkbox
            >
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            viewer: null,
            lighting: false,
            depthTest: false,
            showFrames: false
        };
    },
    mounted() {
        this.viewer = this.$store.state.viewer;
        this.lighting = this.viewer.scene.globe.enableLighting;
        this.depthTest = this.viewer.scene.globe.depthTestAgainstTerrain;
        this.showFrames = this.viewer.scene.debugShowFramesPerSecond;
    },
    methods: {
        setLighting() {
            this.viewer.scene.globe.enableLighting = !this.lighting;
        },
        setDepthTest() {
            this.viewer.scene.globe.depthTestAgainstTerrain = !this.depthTest;
        },
        setShowFrames() {
            this.viewer.scene.debugShowFramesPerSecond = !this.showFrames;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/chileStyleItem.scss";
.child-item {
    height: 40px;
}
</style>