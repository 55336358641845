<template>
    <div class="child-item">
        <span class="title">{{ title }}</span>
        <span class="flag-info">x:</span>
        <InputNumber
            style="font-size:12px;"
            size="small"
            :min="minVal"
            :max="maxVal"
            :step="1.0"
            v-model="pixelOffsetX"
            @on-change="changePixelOffsetX"
        ></InputNumber>
        <span class="flag-info">y:</span>
        <InputNumber
            style="font-size:12px;"
            size="small"
            :min="minVal"
            :max="maxVal"
            :step="1.0"
            v-model="pixelOffsetY"
            @on-change="changePixelOffsetY"
        ></InputNumber>
    </div>
</template>
<script>
import common from "@/assets/js/common.js";
export default {
    props: {
        title: {
            type: String,
            default: "偏移"
        },
        curPixelOffsetX: {
            type: Number,
            default: 0
        },
        curPixelOffsetY: {
            type: Number,
            default: 0
        },
        maxVal: {
            type: Number,
            default: 100
        },
        minVal: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            pixelOffsetX: 0,
            pixelOffsetY: 0
        };
    },
    mounted() {
        this.pixelOffsetX = this.curPixelOffsetX;
        this.pixelOffsetY = this.curPixelOffsetY;
    },
    methods: {
        changePixelOffsetX(e) {
            this.$emit("funcX", e);
        },
        changePixelOffsetY(e) {
            this.$emit("funcY", e);
        }
    },
    watch: {
        curPixelOffsetX(val) {
            this.pixelOffsetX = val;
        },
        curPixelOffsetY(val) {
            this.pixelOffsetY = val;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/chileStyleItem.scss";
</style>