/*
画点标记
*/
import {
    DrawHandle
} from "./drawHandle.js"

import {
    createMarker
} from './entities'

import {
    Cartesian3 as CesiumCartesian3,
    defined as Cesiumdefined
} from "cesium/Cesium";

class Marker extends DrawHandle {
    constructor(viewer, options = null) {
        //super关键字等价于父类构造方法
        super(viewer, options);
        this.marker = undefined;
    }
    onLeftClick(e) {
        if (this.options.endDraw) return;
        let position = this.getMousePoint(e);
        if (position) {
            this.marker = undefined;
            this.addMarker(position)
        }
    }
    onMove(e) {
        this.setMouseStyle()
    }
    addMarker(point) {
        let MarkerPrimitive = createMarker(this)
        if (!Cesiumdefined(this.marker)) {
            this.position = point
            this.marker = new MarkerPrimitive(CesiumCartesian3.fromDegrees(point.lng, point.lat));
        }
    }
}

export {
    Marker
}