<template>
    <div>
        <ul>
            <li v-for="(item, index) in viewPointList" :key="index">
                <span @click="flytoViewPoint(item)">{{ item.name }}</span>
                <span @click="delteViewPoint(index)"
                    ><Icon type="ios-trash-outline"
                /></span>
            </li>
        </ul>
    </div>
</template>

<script>
import common from "@/assets/js/common.js";
export default {
    data() {
        return {
            viewer: null,
            viewPointList: []
        };
    },
    mounted() {
        this.viewer = this.$store.state.viewer;
        this.viewPointList = this.$store.state.viewPointList;
    },
    methods: {
        flytoViewPoint(item) {
            common.flyToViewPoint(
                this.viewer,
                item.destination,
                item.orientation
            );
        },
        delteViewPoint(index) {
            this.viewPointList.splice(index, 1);
        }
    }
};
</script>

<style lang="scss" scoped>
ul li {
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    span {
        cursor: pointer;
    }
    span:first-child {
        float: left;
    }
    span:last-child {
        font-size: 19px;
        float: right;
    }
    span:hover {
        color: #409eff;
    }
}
</style>