<template>
    <div>
        <div class="pop-up">
            <div class="title">测量工具</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in measureList"
                    :key="index"
                    class="box"
                    @click="startMeasure(item)"
                    :class="{
                        'active-box': item.active,
                        'box-small': isSmallScreen
                    }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MeasureDistance } from "@/assets/js/map/measureDistance.js";
import { MeasureArea } from "@/assets/js/map/measureArea.js";
import common from "@/assets/js/common.js";
import {
    Color as CesiumColor,
    Cartesian2 as CesiumCartesian2,
    Cartesian3 as CesiumCartesian3,
    Math as CesiumMath,
    CallbackProperty as CesiumCallbackProperty,
    LabelStyle as CesiumLabelStyle,
    VerticalOrigin as CesiumVerticalOrigin,
    HorizontalOrigin as CesiumHorizontalOrigin,
    HeightReference as CesiumHeightReference,
    ArcType as CesiumArcType
} from "cesium/Cesium";
export default {
    props: {
        isSmallScreen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            viewer: null,
            editGraphic: null,
            measureDistanceHandle: null,
            measureAreaHandle: null,
            measureList: [
                {
                    id: 1,
                    title: "距离测量",
                    img: "./images/tool/measure/measure_distance.png",
                    active: false
                },
                {
                    id: 2,
                    title: "面积测量",
                    img: "./images/tool/measure/measure_area.png",
                    active: false
                },
                {
                    id: 3,
                    title: "清空测量结果",
                    img: "./images/tool/clear.png",
                    active: false
                }
                // {
                //     id: 3,
                //     title: "高度测量",
                //     img: "./images/tool/measure/measure_height.png",
                //     active: false
                // }
            ],
            //线标记的初始化样式
            polylineStyle: {
                polyline: {
                    show: true,
                    positions: [],
                    material: common.rgbaToCesiumColor("rgba(253, 128, 69,1)"),
                    arcType: CesiumArcType.GEODESIC,
                    clampToGround: true,
                    width: 2
                }
            },
            //线标记的初始化样式
            polygonStyle: {
                polygon: {
                    show: true,
                    hierarchy: {},
                    material: common.rgbaToCesiumColor(
                        "rgba(100, 149, 237,0.3)"
                    ),
                    arcType: CesiumArcType.GEODESIC,
                    fill: true,
                    outline: true,
                    outlineColor: common.rgbaToCesiumColor(
                        "rgba(253, 128, 69,1)"
                    ),
                    outlineWidth: 2.0,
                    heightReference: CesiumHeightReference.CLAMP_TO_GROUND
                }
            }
        };
    },
    mounted() {
        this.viewer = this.$store.state.viewer;
        this.editGraphic = this.$store.state.editGraphic;
    },
    destroyed() {
        this.clearDrawHandle();
    },
    methods: {
        startMeasure(item) {
            this.clearDrawHandle();
            this.setItemActive(item);
            if (item.id == this.measureList.length) {
                item.active = false;
                common.clearEntyties("_workMode", this.viewer);
                return;
            }
            //距离测量
            if (item.id == 1) {
                if (this.endDraw(item, this.measureDistanceHandle)) return;
                this.startMeasureDistance(item);
            }
            //面积测量
            else if (item.id == 2) {
                if (this.endDraw(item, this.measureAreaHandle)) return;
                this.startMeasureArea(item);
            }
        },
        startMeasureDistance(item) {
            this.measureDistanceHandle = new MeasureDistance(this.viewer, {
                workMode: "measure",
                endDraw: !item.active,
                polylineStyle: this.polylineStyle,
                editGraphic: this.editGraphic,
                controlPointColor: common.rgbaToCesiumColor(
                    "rgba(254, 3, 3,1)"
                ),
                endCallback: () => {
                    item.active = false;
                    this.measureDistanceHandle = null;
                }
            });
        },
        startMeasureArea(item) {
            this.measureAreaHandle = new MeasureArea(this.viewer, {
                workMode: "measure",
                endDraw: !item.active,
                polygonStyle: this.polygonStyle,
                editGraphic: this.editGraphic,
                controlPointColor: common.rgbaToCesiumColor(
                    "rgba(254, 3, 3,1)"
                ),
                endCallback: () => {
                    item.active = false;
                    this.measureAreaHandle = null;
                }
            });
        },
        setItemActive(item) {
            let active = item.active;
            let clearItem = list => {
                for (let it of list) {
                    it.active = false;
                }
            };
            clearItem(this.measureList);
            item.active = !active;
        },
        endDraw(item, handle) {
            if (!item.active) {
                this.endDrawHandle(handle);
                return true;
            }
            return false;
        },
        endDrawHandle(handle) {
            if (handle) handle.endDrawHandle();
        },
        clearDrawHandle() {
            this.endDrawHandle(this.measureDistanceHandle);
            this.endDrawHandle(this.measureAreaHandle);
            this.measureDistanceHandle = null;
            this.measureAreaHandle = null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/tool.scss";
</style>