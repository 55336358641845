<template>
    <div>
        <div class="pop-up">
            <div class="title">天气工具</div>
            <div class="box-list">
                <div
                    v-for="(item, index) in weatherList"
                    :key="index"
                    class="box"
                    @click="setWeather(item)"
                    :class="{
                        'active-box': item.active,
                        'box-small': isSmallScreen
                    }"
                >
                    <div class="img-flag" :title="item.title">
                        <img :src="item.img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PostProcessStage as CesiumPostProcessStage } from "cesium/Cesium";
export default {
    props: {
        isSmallScreen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            viewer: null,
            weatherList: [
                {
                    id: 1,
                    title: "雨",
                    img: "./images/tool/weather/rain.png",
                    active: false
                },
                {
                    id: 2,
                    title: "雪",
                    img: "./images/tool/weather/snow.png",
                    active: false
                },
                {
                    id: 3,
                    title: "清除天气",
                    img: "./images/tool/clear.png",
                    active: false
                }
            ],
            snowStage: null,
            rainStage: null,
            skyAtmosphere: {},
            fog: {}
        };
    },
    mounted() {
        this.viewer = this.$store.state.viewer;
        //得到天气初始化值
        this.skyAtmosphere.hueShift = this.viewer.scene.skyAtmosphere.hueShift;
        this.skyAtmosphere.saturationShift = this.viewer.scene.skyAtmosphere.saturationShift;
        this.skyAtmosphere.brightnessShift = this.viewer.scene.skyAtmosphere.brightnessShift;
        this.fog.density = this.viewer.scene.fog.density;
        this.fog.minimumBrightness = this.viewer.scene.fog.minimumBrightness;
    },
    methods: {
        setWeather(item) {
            item.active = !item.active;
            if (item.id == 1) {
                if (item.active) {
                    this.addRain();
                } else {
                    this.removeRain();
                }
            } else if (item.id == 2) {
                if (item.active) {
                    this.addSnow();
                } else {
                    this.removeSnow();
                }
            }
            if (item.id == 3) {
                this.weatherList.forEach(it => {
                    it.active = false;
                });
                this.removeSnow();
                this.removeRain();
            }
        },
        addRain() {
            this.rainStage = this.getStages(0);
        },
        removeRain() {
            this.rainStage &&
                this.viewer.scene.postProcessStages.remove(this.rainStage),
                (this.rainStage = null),
                this.resetAtmosphere();
        },
        addSnow() {
            this.snowStage = this.getStages(1);
        },
        getStages(type) {
            let collection = this.viewer.scene.postProcessStages;
            let fs_snow = this.FS_Snow();
            let stage = new CesiumPostProcessStage({
                name: type == 0 ? "czm_rain" : "czm_snow",
                fragmentShader: type == 0 ? this.FS_Rain() : this.FS_Snow()
            });
            collection.add(stage);
            this.viewer.scene.skyAtmosphere.hueShift = -0.8;
            this.viewer.scene.skyAtmosphere.saturationShift = -0.7;
            this.viewer.scene.skyAtmosphere.brightnessShift = -0.33;

            this.viewer.scene.fog.density = 0.001;
            this.viewer.scene.fog.minimumBrightness = 0.8;
            return stage;
        },
        removeSnow() {
            this.snowStage &&
                this.viewer.scene.postProcessStages.remove(this.snowStage),
                (this.snowStage = null),
                this.resetAtmosphere();
        },

        resetAtmosphere() {
            this.viewer.scene.skyAtmosphere.hueShift = this.skyAtmosphere.hueShift;
            this.viewer.scene.skyAtmosphere.saturationShift = this.skyAtmosphere.saturationShift;
            this.viewer.scene.skyAtmosphere.brightnessShift = this.skyAtmosphere.brightnessShift;
            this.viewer.scene.fog.density = this.fog.density;
            this.viewer.scene.fog.minimumBrightness = this.fog.minimumBrightness;
        },
        //定义下雨场景 着色器
        FS_Rain() {
            return `
            uniform sampler2D colorTexture;
				varying vec2 v_textureCoordinates;			
				float hash(float x){
					return fract(sin(x*133.3)*13.13);
			}
			
			void main(void){			
				float time = czm_frameNumber / 60.0;
                vec2 resolution = czm_viewport.zw;
                
                vec2 uv=(gl_FragCoord.xy*2.-resolution.xy)/min(resolution.x,resolution.y);
                vec3 c=vec3(.6,.7,.8);
                
                float a=-.4;
                float si=sin(a),co=cos(a);
                uv*=mat2(co,-si,si,co);
                uv*=length(uv+vec2(0,4.9))*.3+1.;
                
                float v=1.-sin(hash(floor(uv.x*100.))*2.);
                float b=clamp(abs(sin(20.*time*v+uv.y*(5./(2.+v))))-.95,0.,1.)*20.;
                c*=v*b; 
                
                gl_FragColor = mix(texture2D(colorTexture, v_textureCoordinates), vec4(c,1), 0.5);  
			}`;
        },

        //定义下雪场景 着色器
        FS_Snow() {
            return `
            uniform sampler2D colorTexture;
            varying vec2 v_textureCoordinates;
        
            float snow(vec2 uv,float scale)
            {
                float time = czm_frameNumber / 60.0;
                float w=smoothstep(1.,0.,-uv.y*(scale/10.));if(w<.1)return 0.;
                uv+=time/scale;uv.y+=time*2./scale;uv.x+=sin(uv.y+time*.5)/scale;
                uv*=scale;vec2 s=floor(uv),f=fract(uv),p;float k=3.,d;
                p=.5+.35*sin(11.*fract(sin((s+p+scale)*mat2(7,3,6,5))*5.))-f;d=length(p);k=min(d,k);
                k=smoothstep(0.,k,sin(f.x+f.y)*0.01);
                return k*w;
            }
        
            void main(void){
                vec2 resolution = czm_viewport.zw;
                vec2 uv=(gl_FragCoord.xy*2.-resolution.xy)/min(resolution.x,resolution.y);
                vec3 finalColor=vec3(0);
                float c = 0.0;
                c+=snow(uv,30.)*.0;
                c+=snow(uv,20.)*.0;
                c+=snow(uv,15.)*.0;
                c+=snow(uv,10.);
                c+=snow(uv,8.);
                c+=snow(uv,6.);
                c+=snow(uv,5.);
                finalColor=(vec3(c)); 
                gl_FragColor = mix(texture2D(colorTexture, v_textureCoordinates), vec4(finalColor,1), 0.5);        
            }`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/tool.scss";
</style>