<template>
    <div v-if="isShow" class="right-style self-aside-dlg">
        <div class="title-head">
            <span>样式</span>
            <span class="close-dlg"
                ><i class="el-icon-close" @click="closeDlg"></i
            ></span>
        </div>
        <div class="split-line"></div>
        <div
            class="contain-body scrollbar-div"
            :style="'height:' + heightAside + 'px;'"
        >
            <div class="scrollbar">
                <keep-alive>
                    <component
                        v-bind:is="currentTabComponent"
                        :bufferInfo="bufferInfo"
                        :entity="curEntity"
                    ></component>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import MarkerStyle from "./MarkerStyle.vue";
import PolylineStyle from "./PolylineStyle.vue";
import PolygonStyle from "./PolygonStyle.vue";
export default {
    name: "RightStyle",
    components: {
        MarkerStyle,
        PolylineStyle,
        PolygonStyle
    },
    props: {
        heightAside: {
            type: Number,
            default: 100
        },
        bufferInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            isShow: true,
            typeStyle: "",
            curEntity: null,
            viewer: null,
            currentTabComponent: ""
        };
    },
    mounted() {
        this.viewer = this.$store.state.viewer;
        this.typeStyle = this.bufferInfo.type;
        if (this.typeStyle == "marker") {
            this.currentTabComponent = "MarkerStyle";
        } else if (this.typeStyle == "polyline") {
            this.currentTabComponent = "PolylineStyle";
        } else if (this.typeStyle == "polygon") {
            this.currentTabComponent = "PolygonStyle";
        }
        this.findEntity();
    },
    methods: {
        findEntity() {
            this.curEntity = this.viewer.entities.getById(this.bufferInfo.id);
        },
        closeDlg() {
            this.isShow = false;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../../assets/css/selfAsideDlg.less";
.right-style {
    span {
        color: #ffffff;
    }
    position: absolute;
    right: 0;
    width: 270px;
    z-index: 9999;
}
</style>