/*
画折线
*/
import {
    Polyline
} from "./polyline"

class MeasureDistance extends Polyline {
    constructor(viewer, options = null) {
        //super关键字等价于父类构造方法
        super(viewer, options);
    }
    onLeftClick(e) {
        if (!super.onLeftClick(e)) return false;
        let len = this.positions.length
        if (len > 2) {
            this.addClickLabel(this.positions[len - 1], this.getDistanceText())
        }
        return true;
    }
    onMove(e) {
        if (!super.onMove(e)) return false;
        return true;
    }
    onDoubleClick(e) {
        if (!super.onDoubleClick(e)) return false;

        this.updataLabelText('鼠标左键双击结束');
        return true;
    }
    onRightClick(e) {
        super.onRightClick(e)
    }

    addLabel(position) {
        super.addLabel(position)
        if (this.positions.length > 1) {
            this.updataLabelText(this.getDistanceText());
        }
    }

    getDistanceText() {
        let distance = this.getDistance();
        let text = ''
        if (distance > 1000) {
            distance = distance / 1000
            distance = Math.round(distance * 10) / 10
            text = `${distance}公里`
        } else {
            distance = Math.round(distance)
            text = `${distance}米`
        }
        return text;
    }

    getDistance() {
        let distance = 0.0;
        for (let index = 0; index < this.positions.length - 1; index++) {
            let p1 = this.positions[index]
            let p2 = this.positions[index + 1]
            distance += this.getFlatternDistance(p1.lat, p1.lng, p2.lat, p2.lng)
        }
        return distance;
    }

    /** 
     * approx distance between two points on earth ellipsoid 
     * @param {Object} lat1 
     * @param {Object} lng1 
     * @param {Object} lat2 
     * @param {Object} lng2 
     */
    getFlatternDistance(lat1, lng1, lat2, lng2) {
        const EARTH_RADIUS = 6378137.0; //单位M 
        const PI = Math.PI;
        if (lat1 == lat2 && lng1 == lng2) {
            return 0;
        }

        let getRad = function(d) {
            return d * PI / 180.0;
        }

        let f = getRad((lat1 + lat2) / 2);
        let g = getRad((lat1 - lat2) / 2);
        let l = getRad((lng1 - lng2) / 2);

        let sg = Math.sin(g);
        let sl = Math.sin(l);
        let sf = Math.sin(f);

        let s, c, w, r, d, h1, h2;
        let a = EARTH_RADIUS;
        let fl = 1 / 298.257;

        sg = sg * sg;
        sl = sl * sl;
        sf = sf * sf;

        s = sg * (1 - sl) + (1 - sf) * sl;
        c = (1 - sg) * (1 - sl) + sf * sl;

        w = Math.atan(Math.sqrt(s / c));
        r = Math.sqrt(s * c) / w;
        d = 2 * w * a;
        h1 = (3 * r - 1) / 2 / c;
        h2 = (3 * r + 1) / 2 / s;

        return d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg));
    }

}

export {
    MeasureDistance
}