<template>
    <div>
        <div class="style-item">
            <!-- 线标记是否显示 -->
            <div>
                <Checkbox
                    v-model="checkPolyline"
                    @click.native="setPolylineVisible"
                    style="color:#fff"
                    >线标记</Checkbox
                >
            </div>
            <!-- 线的颜色 -->
            <SelfColorPicker
                :curColor="polylineColor"
                @func="changePolylineColor"
            />
            <!-- 线的宽度 -->
            <SelfRange
                title="线宽"
                :minVal="1"
                :maxVal="20"
                :curValue="polylineWidth"
                @func="changePolylineWidth"
            />
        </div>
    </div>
</template>


<script>
import common from "@/assets/js/common.js";
import SelfColorPicker from "./SelfColorPicker.vue";
import SelfRange from "./SelfRange.vue";
export default {
    name: "PolylineStyle",
    components: {
        SelfColorPicker,
        SelfRange
    },
    props: {
        entity: {
            type: Object,
            default: {}
        },
        bufferInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            checkPolyline: true,
            polylineColor: "",
            polylineWidth: 2
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initData();
        });
    },
    methods: {
        initData() {
            let polyline = this.entity._polyline;
            this.checkPolyline = polyline._show._value;
            this.polylineColor = common.cesiumColorToRgba(
                polyline._material._color._value
            );
            this.polylineWidth = polyline._width._value;
        },
        setPolylineVisible() {
            let show = !this.checkPolyline;
            this.bufferInfo.options.polylineStyle.polyline.show = show;
            this.entity._polyline._show._value = show;
        },
        changePolylineColor(e) {
            this.bufferInfo.options.polylineStyle.polyline.material = e;
            this.entity._polyline._material._color._value = e;
        },
        changePolylineWidth(e) {
            this.bufferInfo.options.polylineStyle.polyline.width = e;
            this.entity._polyline._width._value = e;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/selfAsideDlg.less";
@import "../../../assets/css/chileStyleItem.scss";
</style>