/*
公用的函数
*/
import {
    Color as CesiumColor,
    Cartesian3 as CesiumCartesian3,
    Math as CesiumMath
} from "cesium/Cesium";

export default {
    //比较两个结构中的值是否相等
    isObjectValueEqual(a, b) {
        let filter = (arry) => {
            for (let i in arry) {
                if (arry[i] == '__ob__') {
                    arry.splice(i, 1)
                }
            }
            return arry
        }
        let aProps = filter(Object.getOwnPropertyNames(a));
        let bProps = filter(Object.getOwnPropertyNames(b));

        if (aProps.length != bProps.length) {
            return false;
        }
        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i]
            let propA = a[propName]
            let propB = b[propName]
            if ((typeof(propA) === 'object')) {
                if (this.isObjectValueEqual(propA, propB)) {
                    return true
                } else {
                    return false
                }
            } else if (propA !== propB) {
                return false
            } else {}
        }
        return true
    },
    cesiumColorToRgba(cesiumColor) {
        return `rgba(${cesiumColor.red * 255},${cesiumColor.green *
            255},${cesiumColor.blue * 255},${cesiumColor.alpha},)`;
    },
    rgbaToCesiumColor(rgba) {
        rgba = rgba.replace('rgba(', '')
        rgba = rgba.replace(')', '')
        let list = rgba.split(',')
        return new CesiumColor(list[0] / 255, list[1] / 255, list[2] / 255, parseFloat(list[3]))
    },
    setHomeViewPoint(viewer, postion = {
        lng: 111.07,
        lat: 39.05,
        height: 15000000
    }) {
        let destination = CesiumCartesian3.fromDegrees(
            postion.lng,
            postion.lat,
            postion.height
        )
        let orientation = {
            heading: CesiumMath.toRadians(0),
            pitch: CesiumMath.toRadians(-90),
            roll: CesiumMath.toRadians(0)
        }
        this.flyToViewPoint(viewer, destination, orientation)
    },

    flyToViewPoint(viewer, destination, orientation) {
        //当前视点
        viewer.camera.flyTo({
            destination,
            orientation
        });
    },

    //清除标记
    clearEntyties(flag, viewer) {
        let list = viewer.entities._entities._array;
        for (let index = list.length - 1; index >= 0; index--) {
            let item = list[index];
            if (item.hasOwnProperty(flag)) {
                viewer.entities.remove(item);
            }
        }
    }
}