import { render, staticRenderFns } from "./ShowBottomInfo.vue?vue&type=template&id=70a3e640&scoped=true&"
import script from "./ShowBottomInfo.vue?vue&type=script&lang=js&"
export * from "./ShowBottomInfo.vue?vue&type=script&lang=js&"
import style0 from "./ShowBottomInfo.vue?vue&type=style&index=0&id=70a3e640&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a3e640",
  null
  
)

export default component.exports