<template>
    <div class="right-tool" :class="{ 'right-tool-small': isSmallScreen }">
        <div class="zoom" :class="{ zoomScale: isSmallScreen }">
            <div title="放大" @click="zoomIn">
                <i class="ivu-icon ivu-icon-ios-add"></i>
            </div>
            <div title="重置" @click="zoomReset">
                <i class="ivu-icon ivu-icon-ios-refresh"></i>
            </div>
            <div title="缩小" @click="zoomOut">
                <i class="ivu-icon ivu-icon-ios-remove"></i>
            </div>
        </div>
        <div
            class="location"
            :class="{ locationScale: isSmallScreen }"
            @click="location"
            title="当前位置"
        >
            <i class="ivu-icon ivu-icon-ios-locate-outline"></i>
        </div>
        <div
            class="location"
            :class="{ otherScale: isSmallScreen }"
            @click="fullScreen"
            :title="isFull ? '退出全屏' : '全屏'"
        >
            <i class="ivu-icon ivu-icon-md-expand"></i>
        </div>
    </div>
</template>

<script>
import common from "@/assets/js/common.js";
const coordtransform = require("coordtransform");
export default {
    props: {
        heightAside: {
            type: Number,
            default: 100
        },
        widthApp: {
            type: Number,
            default: 600
        }
    },
    data() {
        return {
            lighting: false,
            depthTest: false,
            isFull: false,
            isFullFlag: false,
            isSmallScreen: false
        };
    },

    mounted() {},
    methods: {
        zoomIn() {
            this.viewer.camera.zoomIn(
                this.viewer.camera.positionCartographic.height / 2
            );
        },
        zoomOut() {
            this.viewer.camera.zoomOut(
                this.viewer.camera.positionCartographic.height
            );
        },
        zoomReset() {
            common.setHomeViewPoint(this.viewer);
        },
        getPosition() {
            let _this = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        let latitude = position.coords.latitude;
                        let longitude = position.coords.longitude;
                        let loc = {
                            lng: longitude,
                            lat: latitude,
                            height: 500
                        };
                        common.setHomeViewPoint(_this.viewer, loc);
                    },
                    function(error) {
                        let BMap = window.BMap;
                        let geolocation = new BMap.Geolocation();

                        geolocation.enableSDKLocation(); //允许SDK辅助
                        geolocation.getCurrentPosition(function(position) {
                            if (this.getStatus() == 0) {
                                let pt = position.point;
                                let bd09togcj02 = coordtransform.bd09togcj02(
                                    pt.lng,
                                    pt.lat
                                );
                                let gcj02towgs84 = coordtransform.gcj02towgs84(
                                    bd09togcj02[0],
                                    bd09togcj02[1]
                                );
                                let loc = {
                                    lng: gcj02towgs84[0],
                                    lat: gcj02towgs84[1],
                                    height: 500
                                };
                                common.setHomeViewPoint(_this.viewer, loc);
                            }
                        });
                    }
                );
            }
        },
        //当前位置
        location() {
            this.getPosition();
        },
        fullScreen() {
            this.isFull = !this.isFull;
            if (this.isFull) {
                setTimeout(() => {
                    this.isFullFlag = this.isFull;
                }, 500);
                var full = document.getElementById("app");
                this.launchIntoFullscreen(full);
            } else {
                this.exitFullscreen();
            }
        },
        launchIntoFullscreen(element) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        },
        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    },
    computed: {
        viewer() {
            if (this.$store.state.viewer) {
                return this.$store.state.viewer;
            }
            return null;
        }
    },
    watch: {
        heightAside(val) {
            if (this.isFullFlag) {
                this.isFull = false;
                this.isFullFlag = false;
            }
        },

        widthApp(val) {
            if (val < 600) {
                this.isSmallScreen = true;
            } else {
                this.isSmallScreen = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.right-tool {
    position: absolute;
    right: 22px;
    top: 200px;
    width: 50px;
    height: 400px;
    z-index: 2;
    text-align: center;
    i:hover {
        color: #68adfe;
    }
}
.right-tool-small {
    right: 0px;
}
.zoomScale {
    transform: translateY(-30px) scale(2);
}
.zoom {
    margin: 0 auto;
    width: 24px;
    height: 60px;
    background: #3f4854;
    border-radius: 100px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    div {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        i {
            color: #fff;
            font-size: 18px;
        }
    }
}

.locationScale {
    transform: translateY(20px) scale(1.5);
}

.otherScale {
    transform: translateY(40px) scale(1.5);
}

.location {
    cursor: pointer;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 20px;
    }
}
</style>