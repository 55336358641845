/*
画折线
*/
import {
    Polygon
} from "./polygon"

class MeasureArea extends Polygon {
    constructor(viewer, options = null) {
        //super关键字等价于父类构造方法
        super(viewer, options);
    }
    onLeftClick(e) {
        if (!super.onLeftClick(e)) return false;
        let len = this.positions.length
        if (len > 2) {
            /*let distance = this.getDistance();
            let text = ''
            if (distance > 1000) {
                distance = distance / 1000
                distance = Math.round(distance * 10) / 10
                text = `${distance}公里`
            } else {
                distance = Math.round(distance)
                text = `${distance}米`
            }
            this.addClickLabel(this.positions[len - 1], text)*/
        }
        return true;
    }
    onMove(e) {
        if (!super.onMove(e)) return false;
        return true;
    }
    onDoubleClick(e) {
        let len = this.positions.length
        if (len > 2) {
            this.addClickLabel(this.positions[len - 1], this.getAreaText())
        }
        if (!super.onDoubleClick(e)) return false;
        this.updataLabelText('鼠标左键双击结束');
        return true;
    }
    onRightClick(e) {
        super.onRightClick(e)
    }

    addLabel(position) {
        super.addLabel(position)
        if (this.positions.length > 1) {
            this.updataLabelText(this.getAreaText());
        }
    }

    getAreaText() {
        let area = this.computeSignedArea(this.positions);
        let text = ''
        if (area > 1000000) {
            area = area / 1000000
            area = Math.round(area * 100) / 100
            text = `${area}平方公里`
        } else {
            area = Math.round(area)
            text = `${area}平方米`
        }
        return text;
    }

    computeSignedArea(path) {
        //传入path：{[{lat:,lng:}],[{lat:,lng:}],[{lat:,lng:}]}
        let radius = 6371009
        let len = path.length;
        if (len < 3) return 0;
        let total = 0;
        let prev = path[len - 1];
        let prevTanLat = Math.tan(((Math.PI / 2 - prev.lat / 180 * Math.PI) / 2));
        let prevLng = (prev.lng) / 180 * Math.PI;
        for (let i in path) {
            let tanLat = Math.tan((Math.PI / 2 -
                (path[i].lat) / 180 * Math.PI) / 2);
            let lng = (path[i].lng) / 180 * Math.PI;
            total += this.polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
            prevTanLat = tanLat;
            prevLng = lng;
        }
        return Math.abs(total * (radius * radius));
    }
    polarTriangleArea(tan1, lng1, tan2, lng2) {
        let deltaLng = lng1 - lng2;
        let t = tan1 * tan2;
        return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
    }
}

export {
    MeasureArea
}